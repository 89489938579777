<div class="middle-header-area">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">

    <div class="container">
        <div class="row align-items-center">
            <div class="col-4">
                <div class="middle-header">
                    <h1>
                        <a routerLink="/datos">
                          <img src="assets/images/montepio.png" class="img-fluid" alt="Responsive image">
                        </a>
                    </h1>
                </div>
            </div>
            <div class="col-8">
              <ul class="middle-header-content">
                <li>
                    <i class="flaticon-039-emergency-call"></i>
                    Teléfonos
                    <span><a href="tel:088123654987">94 634 51 57 - 644 75 22 56</a></span>
                </li>
                <li>
                    <i class="flaticon-040-back-in-time"></i>
                    Horario
                    <span>Lunes a viernes: 9:30 - 14:00</span>
                </li>
              </ul>
                <!-- <ul class="middle-header-content">
                  <li>
                    <p>Ainhoa López</p>
                  </li>
                    <li>
                      <i class="flaticon-042-user"></i>
                    </li>
                    <li>
                      <i class="fa-solid fa-arrow-right-from-bracket"></i>
                    </li>
                </ul> -->
            </div>
        </div>
    </div>
</div>
