<div class="not-found-area ptb-100">
    <div class="container">
        <div class="not-found-content">
            <img src="assets/images/error.png" alt="error-image">
            <h3>Oops! That page can't be found</h3>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <a routerLink="/datos" class="default-btn">
                Inicio
            </a>
        </div>
    </div>
</div>
