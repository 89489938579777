
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from '../../../core/services/cookie.service';

@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

    constructor(
        public router: Router,
        public cookieService: CookieService,
        
    ) { }

    ngOnInit(): void {}

    aceptarCookies(){
        this.cookieService.createCookie('cookiesAceptadas', 'true', 3600*24*30*6)
    }
}