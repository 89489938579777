<!-- Page Banner -->
<div
    class="page-banner-area ptb-100 four"
    style="background-image: url(../../../../assets/images/information.jpg);"
>
    <div class="container">
        <div class="page-banner-content">
          <h2 *ngIf="order=='normativa'">Normativa  y políticas</h2>
          <h2 *ngIf="order=='auditoria'">Informes de auditoría</h2>
          <h2 *ngIf="order=='junta'">Junta de Gobierno</h2>
          <h2 *ngIf="order=='asamblea'">Asamblea general</h2>
          <h2 *ngIf="order=='derechos'">Declaración de Principios de Inversión</h2>
        </div>
    </div>
</div>


<div class="service-details-area pt-100 pb-100">
  <div class="container">
      <div class="row">
        @if(order=='normativa'){
          <div class="col-lg-12 col-md-12">
            <aside class="widget-area">
                <div class="widget widget_download_file">
                    <h1 class="widget-title">Documentación</h1>
                    <div>
                      <h6>Estatutos y reglamentos</h6>
                      <ul class="download-file-list">
                          <li>
                              <a href="../../../../assets/files/Estatutos y Reglamento Montepío 2024.pdf" target="_blank">
                                  <img src="../../../../assets/images/pdf.png" alt="image">
                                  <div class="title">
                                      <h4>Estatutos y Reglamento Montepío 2024</h4>
                                      <span>PDF | 326 KB</span>
                                  </div>
                              </a>
                          </li>
                      </ul>
                    </div>

                </div>
            </aside>
          </div>
        }

        @if(order=='auditoria'){
          <div class="col-lg-12 col-md-12">
            <aside class="widget-area">
                <div class="widget widget_download_file">
                    <h1 class="widget-title">Documentación</h1>
                    <div>
                      <h6>Informes Auditores</h6>
                      <ul class="download-file-list">
                          <li>
                            <a href="../../../../assets/files/Informe Auditoría de Cuentas 2021.pdf" target="_blank">
                              <img src="../../../../assets/images/pdf.png" alt="image">
                                  <div class="title">
                                      <h4>Informe Auditoría de Cuentas 2021</h4>
                                      <span>PDF | 12 MB</span>
                                  </div>
                              </a>
                          </li>
                          <li>
                            <a href="../../../../assets/files/Informe Auditoría de Cuentas 2022.pdf" target="_blank">
                              <img src="../../../../assets/images/pdf.png" alt="image">
                                <div class="title">
                                    <h4>Informe Auditoría de Cuentas 2022</h4>
                                    <span>PDF | 13.9 MB</span>
                                </div>
                            </a>
                          </li>
                          <li>
                            <a href="../../../../assets/files/Informe Auditoría de Cuentas 2022.pdf" target="_blank">
                              <img src="../../../../assets/images/pdf.png" alt="image">
                                <div class="title">
                                    <h4>Informe Auditoría de Cuentas 2023</h4>
                                    <span>PDF | 18.6 MB</span>
                                </div>
                            </a>
                          </li>
                      </ul>
                    </div>

                </div>
            </aside>
          </div>
        }

        @if(order=='asamblea'){
          <div class="col-lg-12 col-md-12">
            <aside class="widget-area">
                <div class="widget widget_download_file">
                    <h1 class="widget-title">Documentación</h1>
                    <!-- <div>
                      <h6>Prestaciones Anuales</h6>
                      <ul class="download-file-list">
                          <li>
                              <a href="../../../../assets/files/Presentación Asamblea General Montepío 2022.pdf" target="_blank">
                                  <img src="../../../../assets/images/pdf.png" alt="image">
                                  <div class="title">
                                      <h4>Presentación Asamblea General Montepío 2022</h4>
                                      <span>PDF | 3.8 MB</span>
                                  </div>
                              </a>
                          </li>
                          <li>
                            <a href="../../../../assets/files/Presentación Asamblea General Montepío 2023.pdf" target="_blank">
                              <img src="../../../../assets/images/pdf.png" alt="image">
                                  <div class="title">
                                      <h4>Presentación Asamblea General Montepío 2023</h4>
                                      <span>PDF | 3.5 MB</span>
                                  </div>
                              </a>
                          </li>
                          <li>
                            <a href="../../../../assets/files/Presentación Asamblea General Montepío 2024.pdf" target="_blank">
                              <img src="../../../../assets/images/pdf.png" alt="image">
                              <div class="title">
                                  <h4>Presentación Asamblea General Montepío 2024</h4>
                                  <span>PDF | 3.4 MB</span>
                                </div>
                            </a>
                          </li>
                      </ul>
                    </div> -->
                    <h6>La documentación se actualizará próximamente</h6>

                </div>
            </aside>
          </div>
        }

        @if(order=='derechos'){
          <div class="col-lg-12 col-md-12">
            <aside class="widget-area">
                <div class="widget widget_download_file">
                    <h1 class="widget-title">Documentación</h1>
                    <div>
                      <h6>Principios de inversiones</h6>
                      <ul class="download-file-list">
                          <li>
                            <a href="../../../../assets/files/Declaracion principios de Inversion2024.pdf" target="_blank">
                              <img src="../../../../assets/images/pdf.png" alt="image">
                                  <div class="title">
                                      <h4>Declaración de Principios de Inversión 2024</h4>
                                      <span>PDF | 1.3 MB</span>
                                  </div>
                              </a>
                          </li>
                      </ul>
                    </div>

                </div>
            </aside>
          </div>
        }

        @if(order=='junta'){
          <div class="col-lg-12 col-md-12">
            <div class="info-desc">
              <h1>Composición de la Junta de Gobiero del Montepío del Igualatorio</h1>
              <div class="content">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Cargo</th>
                      <th scope="col">Nombre</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Presidente/a</td>
                      <td>MAGDALENA MUGICA PORTILLO</td>
                    </tr>
                    <tr>
                      <td>Vicepresidente</td>
                      <td>IÑIGO VILAR ACHABAL</td>
                    </tr>
                    <tr>
                      <td>Secretario/a no miembro</td>
                      <td>JOSE ORIOL PROSPER CARDOSO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>JOSE MARIA CASTAÑOS DEL MOLINO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>AINHOA RUBIO EREÑO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>AITOR BARAÑANO ABASOLO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>JESUS GORROCHATEGUI EREÑO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>MARIO PUGA BARREDO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>MIGUEL ZALDÚA ARRESE</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>MYRIAM BOYERO URANGA</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
      </div>
  </div>
</div>

