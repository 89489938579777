<div
    class="navbar-area"
    [ngClass]="{'d-none': router.url === '/' || router.url === '/login'  || this.router.url.startsWith('/recuperarClave'), 'sticky': isSticky}"
>
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <!-- <a class="navbar-brand" routerLink="/" (click)="toggleClass()">
                  <img src="assets/images/montepio.png" class="img-fluid" alt="Responsive image">
            </a> -->
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item">
                      <a routerLink="/datos" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        Mis Datos
                      </a>
                    </li>
                    <li class="nav-item">
                      <a routerLink="/prestacion" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        Prestaciones
                      </a>
                    </li>
                    <li class="nav-item">
                      <a routerLink="/misAportaciones" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        Aportaciones
                      </a>
                    </li>
                    <li class="nav-item">
                      <a routerLink="/derechosEconomicos" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                          Derechos Económicos
                      </a>
                    </li>
                    <li class="nav-item">
                      <a routerLink="/certificados" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        Certificados
                      </a>
                    </li>
                    <li class="nav-item">
                      <a routerLink="/solicitudesPrestacion" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        Solicitudes de prestación
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                          Información
                      </a>
                      <ul class="dropdown-menu">
                          <li class="nav-item">
                              <a routerLink="/info" [queryParams]="{submenu: 'normativa'}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                  Normativa y políticas
                              </a>
                          </li>
                          <li class="nav-item">
                            <a routerLink="/info" [queryParams]="{submenu: 'auditoria'}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                Informes de Auditoría
                            </a>
                          </li>
                          <li class="nav-item">
                            <a routerLink="/info" [queryParams]="{submenu: 'junta'}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                Junta de Gobierno
                            </a>
                          </li>
                          <li class="nav-item">
                            <a routerLink="/info" [queryParams]="{submenu: 'asamblea'}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                Asamblea General
                            </a>
                          </li>
                          <li class="nav-item">
                            <a routerLink="/info" [queryParams]="{submenu: 'derechos'}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                              Declaración de Principios de Inversión
                            </a>
                          </li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <a routerLink="/contacto" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        Contacto
                      </a>

                    </li>
                    <!-- <li class="nav-item">
                        <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                            About Us
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    About Us - 1
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    About Us - 2
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/about-us-3" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    About Us - 3
                                </a>
                            </li>
                        </ul>
                    </li> -->


                    <!-- <li class="nav-item">
                        <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                            Pages
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/appointment" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Book An Appointment
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/pricing" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Pricing
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/testimonials" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Testimonials
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/register-for-vaccine" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Register for Vaccine
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/how-vaccines-work" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    How Vaccines Work
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                                    Portfolio
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/portfolio" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                            Portfolio
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/portfolio-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                            Portfolio Details
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/login" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Login
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/register" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Register
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/forgot-password" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Forgot Password
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Privacy Policy
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Terms & Conditions
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/not-found" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    404 Error Page
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/coming-soon" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                    Coming Soon
                                </a>
                            </li>
                        </ul>
                    </li> -->


                </ul>


                <ul class="navbar-nav for-responsive">
                  <li class="nav-item">
                    <a routerLink="/datos" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                      Mis Datos
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/prestacion" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                      Prestaciones
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/misAportaciones" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                      Aportaciones
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/derechosEconomicos" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                        Derechos Económicos
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/certificados" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                      Certificados
                    </a>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/solicitudesPrestacion" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                      Solicitudes de prestación
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                        Información
                    </a>
                    <ul class="dropdown-menu">
                        <li class="nav-item">
                            <a routerLink="/info" [queryParams]="{submenu: 'normativa'}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                Normativa y políticas
                            </a>
                        </li>
                        <li class="nav-item">
                          <a routerLink="/info" [queryParams]="{submenu: 'auditoria'}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                              Informes de Auditoría
                          </a>
                        </li>
                        <li class="nav-item">
                          <a routerLink="/info" [queryParams]="{submenu: 'junta'}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                              Junta de Gobierno
                          </a>
                        </li>
                        <li class="nav-item">
                          <a routerLink="/info" [queryParams]="{submenu: 'asamblea'}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                              Asamblea General
                          </a>
                        </li>
                        <li class="nav-item">
                          <a routerLink="/info" [queryParams]="{submenu: 'derechos'}" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                            Declaración de Principios de Inversión
                          </a>
                        </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a routerLink="/contacto" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                      Contacto
                    </a>

                  </li>
                    <!-- <li class="nav-item">
                        <a href="javascript:void(0)" class="dropdown-toggle nav-link">
                            About Us
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    About Us - 1
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/about-us-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    About Us - 2
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/about-us-3" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">
                                    About Us - 3
                                </a>
                            </li>
                        </ul>
                    </li> -->

                </ul>
                <!-- <div class="others-option d-flex align-items-center">
                    <div class="option-item">
                        <div class="search-btn" (click)="searchToggleClass()">
                            <i class="flaticon-035-search-interface-symbol"></i>
                        </div>
                    </div>

                </div> -->
            </div>
        </nav>
    </div>
</div>

