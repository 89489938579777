import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-solicitudes-prestacion',
  templateUrl: './solicitudes-prestacion.component.html',
  styleUrls: ['./solicitudes-prestacion.component.scss']
})
export class SolicitudesComponent implements AfterViewInit, OnInit {
  userName: string = ''
  constructor(
    public commonService: CommonService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.userName = JSON.parse(localStorage.getItem("currentUser") || '').name
    this.userName = this.userName.toLowerCase().replace(/(?:^|\s)\S/g, match => match.toUpperCase())
    this.commonService.nombre = this.userName
  }

  ngAfterViewInit() {
    const delay = 0; // <= eso es ser rapido
    setTimeout(() => {
      this.commonService.espacioComponente()
     }, delay);

  }

  detalle(section:string){
    this.router.navigate(['/solicitudesPrestacionDetalle'], { queryParams: { submenu: section } });
  }
}
