import { CookieService } from './../services/cookie.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    public cookieService: CookieService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authToken = this.cookieService.getCookie("token");
    if (authToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `${authToken}`,
        }
      });
    }
    return next.handle(req);
  }
}

export const authInterceptor: HttpInterceptorFn = (req, next) => {

  const authToken = 'YOUR_AUTH_TOKEN_HERE';
  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authToken}`
    }
  });
  return next(authReq);
}

export class MyInterceptor implements HttpInterceptor {
  intercept(req : HttpRequest<any>, next : HttpHandler) : Observable<HttpEvent<any>> {
    const httpReq = req.clone({
      url: req.url.replace("http://", "https://")
    });
    return next.handle(httpReq);
  }
}
