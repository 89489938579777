<div class="contenido">
  <!-- Page Banner -->
<div
class="page-banner-area ptb-100 four"
style="background-image: url(../../../../assets/images/prestaciones.jpg);"
>
<div class="container">
    <div class="page-banner-content">
        <h2>Prestaciones</h2>
    </div>
</div>
</div>

@if(noPrestaciones){
  <div class="container">
    <div class="row preBienvenido">
    <span class="row sub-title">
      <div class="row">
        <h5 class="vacio">
          No hay prestaciones para este número de asociado
        </h5>
      </div>
    </span>
    </div>
  </div>
} @else{


@if (loaded) {
  <div class="container">
    <div class="row body">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="materialTableHeader row">
            <div class="left col-lg-3 col-sm-12">
              <div class="header-buttons-left ms-0 ">
                <div class="tbl-title">
                  <h2>Año</h2>
                </div>
                <div class="tbl-search-box">
                  <label for="search-input"><i class="flaticon-035-search-interface-symbol"></i></label>
                  <input placeholder="" type="number" #filter class="browser-default search-field" size="4"
                    aria-label="Search box"
                    min="0"
                    (keyup)="applyFilter(filter.value)">
                </div>
              </div>
            </div>
            <div class="range col-lg-3 col-sm-12">
              <div class="header-buttons-left ms-0 ">
                <mat-form-field  id="range-date" class="range-date">
                  <mat-label>Rango de fechas</mat-label>
                  <mat-date-range-input [formGroup]="range" [rangePicker]="picker" class="range-input">
                    <input matStartDate formControlName="start" placeholder="Inicio">
                    <input matEndDate formControlName="end" placeholder="Fin">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>

                  @if (range.controls.start.hasError('matStartDateInvalid')) {
                    <mat-error>Invalid start date</mat-error>
                  }
                  @if (range.controls.end.hasError('matEndDateInvalid')) {
                    <mat-error>Invalid end date</mat-error>
                  }
                </mat-form-field>
              </div>
            </div>
            <div class="middle col-lg-4 col-sm-6 select">
              <mat-form-field id="select" class="select" (click)="cambioScroll()">
                <mat-label>Tipo</mat-label>
                <mat-select #tipoPrestacion (selectionChange)="applyTipoFilter($event.value)" class="select" >
                  <mat-option value="all">Todos</mat-option>
                  <div class="select">
                    @for (tipo of tipoPrestaciones; track $index) {
                      <mat-option value="{{tipo.nombre}}">{{tipo.nombre}}</mat-option>
                    }
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="right col-lg-2 col-sm-6">
              <ul class="tbl-export-btn">
                <li>
                  <div class="export-button m-l-10" matTooltip="XLSX" (click)="exportarAPDF()">
                    <img src="../../../../../assets/images/pdf.png" alt="" />
                  </div>
                </li>
                <li>
                  <div class="export-button m-l-10" matTooltip="XLSX" (click)="exportarAExcel()">
                    <img src="../../../../../assets/images/xlsx.png" alt="" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="overflow-auto">
            <div class="responsive_table">
              <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource">

                  <ng-container matColumnDef="fecha_pago">
                    <th mat-header-cell *matHeaderCellDef class="fecha-cobro-col" (click)="sortData('fecha_pago')"> Fecha <i class="flaticon-025-down-arrow"></i></th>
                    <i></i>
                    <mat-icon *ngIf="sortColumn === 'fecha_pago'" aria-hidden="false" aria-label="Example home icon" fontIcon="home"></mat-icon>
                    <td mat-cell *matCellDef="let prestacion" class="fecha-cobro-col"> {{prestacion.fecha_pago}} </td>
                  </ng-container>

                  <ng-container matColumnDef="tipo">
                    <th mat-header-cell *matHeaderCellDef (click)="sortData('tipo')" class="tipo-col"> Tipo prestación <i class="flaticon-025-down-arrow"></i></th>

                    <td mat-cell *matCellDef="let prestacion" class="tipo-col"> {{prestacion.tipo}} </td>
                  </ng-container>

                  <ng-container matColumnDef="importe_neto">
                    <th mat-header-cell *matHeaderCellDef (click)="sortData('importe_neto')" class="importe_tabla"> Importe <i class="flaticon-025-down-arrow"></i></th>
                    <td mat-cell *matCellDef="let prestacion" class="importe_tabla">   {{ formatCurrency(prestacion.importe_neto) }} </td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>


              </div>

            </div>
          </div>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                          showFirstLastButtons
                          aria-label="Select page of prestaciones">
          </mat-paginator>
        </div>
      </div>
    </div>

    <p class="msn">En caso de necesitar un certificado de las prestaciones realizadas, acudir a la página de certificados.</p>


  </div>
} @else{
  <div class="gif-container">
    <img src="../../../../../assets/images/load-8510_256.gif" alt="GIF animado">
  </div>
}




}

