import { ApiService } from './../../../core/services/api.service';
import { AfterViewInit, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { distinctUntilChanged } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.scss'
})

export class RecoverPasswordComponent implements OnInit, AfterViewInit{

  dni: string = '';
  email: string = '';
  viewHeight: number = 0;
  minHeight:number = 475;

  validToken: Boolean = false

  errorMessage: string = '';

  recover: Boolean = false;
  recoverForm: FormGroup;
  destroyRef  =inject(DestroyRef)
  loading: Boolean = false


  constructor(
    public commonService: CommonService,
    public apiService : ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router
  ) {
    this.recoverForm = this.fb.group({
      dni: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]]
    })
  }

  ngOnInit(): void {
    this.setComponentHeight()

  }

  ngAfterViewInit() {
    const delay = 0; // <= eso es ser rapido
    setTimeout(() => {
      this.commonService.espacioComponente()
     }, delay);

  }

  isOpen = false;
  openPopup(): void {
      this.isOpen = true;
  }
  closePopup(): void {
      this.isOpen = false;
      this.router.navigate(['/login']);
  }

  recoverPassword() {
    this.loading = true
    this.errorMessage = ''
    if (this.commonService.isValidSpanishDNI(this.dni)) {
      this.apiService.resetPassPart1(this.dni, this.email).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          if (res) {
            if(res.code==210){
              this.openPopup();
              const delay = 0; // <= eso es ser rapido
              setTimeout(() => {
                this.errorMessage = ''
              }, delay);
            } if(res.code==530){
              this.errorMessage = 'DNI o email incorrecto';
              this.loading = false
            } if(res.code==531){
              this.errorMessage = 'El asociado no existe con el DNI indicado';
              this.loading = false
            }else{
              this.errorMessage = 'Error al enviar el email';
              this.loading = false
            }
          } else {
            this.errorMessage = 'Error al enviar el email';
            this.loading = false
          }
        },
        error: (error) => {
          this.errorMessage = 'Error al enviar el email';
          this.loading = false


        },
      });
    } else {
      this.errorMessage = 'DNI inválido';
      this.loading = false

    }

  }

  setComponentHeight(){
    const header = document.getElementById('header');
    const footer = document.getElementById('footer');
    const component = document.getElementById('componente');

    if (header && component) {
      const headerHeight = header.offsetHeight;
      const footerHeight = footer ? footer.offsetHeight : 0;
      this.viewHeight = window.innerHeight - headerHeight - footerHeight;
      if(this.viewHeight<this.minHeight){
        component.style.minHeight = this.minHeight+'px';    // set height

      } else{
        component.style.minHeight = `${this.viewHeight}px`;    // set height

      }
    }
  }
}
