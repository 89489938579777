
export class Documento{

    name: string
    data: string


    constructor() {
      this.name = ''
      this.data = ''

    }

   }
