<!-- Footer -->
<footer
    class="footer-area"
        style="background-image: url(assets/images/footer-bg.jpg);"ç
        id="footer"

>
<div class="container">
  <div class="row">
      <div class=" logo_footer col-lg-2 col-sm-12 ">
        <h1>
          <a routerLink="/datos">
            <img src="../../../../assets/images/logotipo1.png" class="img-fluid" alt="Responsive image">
          </a>
        </h1>
      </div>
      <div class="col-lg-5 col-sm-12">
        <div class="single-footer-widget info">
            <ul class="footer-information row">
                <li>
                  <i class="flaticon-041-placeholder col-4"></i>
                  Dirección
                  <span>Gran Vía Diego López de Haro, 81 - 5º Dpto 4 - 48011 Bilbao - Bizkaia</span>
                </li>
                <li>
                    <i class="flaticon-039-emergency-call col-4"></i>
                    Teléfonos
                    <span><a href="tel:088123654987">94 634 51 57 - 644 75 22 56</a></span>
                </li>
                <li>
                    <i class="flaticon-040-back-in-time col-4"></i>
                    Horario
                    <span>Lunes a viernes: 9:30 - 14:00</span>
                </li>

            </ul>
        </div>
      </div>
      <div class="col-lg-5 col-sm-12">
          <div class="single-footer-widget links ps-5">
              <ul class="quick-links">
                  <li>
                      <a routerLink="/datos">Mis datos</a>
                  </li>
                  <li>
                      <a routerLink="/prestacion">Prestaciones</a>
                  </li>
                  <li>
                      <a routerLink="/misAportaciones">Aportaciones</a>
                  </li>
                  <li>
                      <a routerLink="/certificados">Certificados</a>
                  </li>
                  <li>
                      <a routerLink="/solicitudesPrestacion">Solicitudes de prestación</a>
                  </li>
                  <li>
                    <a routerLink="/info">Información</a>
                  </li>
                  <li>
                      <a routerLink="/contacto">Contacto</a>
                  </li>
              </ul>
          </div>
      </div>

  </div>
</div>
<div
    class="copyright-area row"
>
    <div class="container-copyright">
        <div class="copyright-area-content">
          <p>© 2024 Montepío Del Igualatorio • Todos Los Derechos Reservados</p>
          <div class="container-politicas">
            <div class="politica-area-content">
              <p><a href="https://www.montepioigualatorio.com/privacidad/">Privacidad</a> | <a href="https://www.montepioigualatorio.com/aviso-legal/">Aviso legal</a> | <a href="https://www.montepioigualatorio.com/cookies/">Política de cookies</a></p>
            </div>
          </div>
        </div>
    </div>

</div>
</footer>
