
export class TipoPrestacion{

    id: number
    nombre: string
    texto_fichero: string
    importe_cuota: number
    cuenta_contable: string
    cuenta_tercero: string
    activo: string
    fecha_estado: string

    constructor() {
      this.id = 0
      this.nombre = ''
      this.texto_fichero = ''
      this.importe_cuota = 0
      this.cuenta_contable = ''
      this.cuenta_tercero = ''
      this.activo = ''
      this.fecha_estado = ''


    }

   }
