<!-- Page Banner -->
<div
    class="page-banner-area ptb-100 four"
    style="background-image: url(../../../../assets/images/information.jpg);"
>
    <div class="container">
        <div class="page-banner-content">
          <h2 *ngIf="order=='normativa'">Normativa  y políticas</h2>
          <h2 *ngIf="order=='auditoria'">Informes de auditoría</h2>
          <h2 *ngIf="order=='junta'">Junta de Gobierno</h2>
          <h2 *ngIf="order=='asamblea'">Asamblea general</h2>
          <h2 *ngIf="order=='derechos'">Declaración de Principios de Inversión</h2>
        </div>
    </div>
</div>


<div class="service-details-area pt-100 pb-100">
  <div class="container">
      <div class="row">
        @if(order=='normativa'){
          <div class="col-lg-12 col-md-12">
            <aside class="widget-area">
                <div class="widget widget_download_file">
                    <h1 class="widget-title">Documentación</h1>
                    <div>
                      <h6>Estatutos y reglamentos</h6>
                      <ul class="download-file-list">
                          <li>
                              <a href="../../../../assets/files/Estatutos y Reglamento Montepío 2024.pdf" target="_blank">
                                  <img src="../../../../assets/images/pdf.png" alt="image">
                                  <div class="title">
                                      <h4>Estatutos y Reglamento Montepío 2024</h4>
                                      <span>PDF | 326 KB</span>
                                  </div>
                              </a>
                          </li>
                      </ul>
                    </div>

                </div>
            </aside>
          </div>
        }

        @if(order=='auditoria'){
          <div class="col-lg-12 col-md-12">
            <aside class="widget-area">
                <div class="widget widget_download_file">
                    <h1 class="widget-title">Documentación</h1>
                    <div>
                      <h6>Informes Auditores</h6>
                      <ul class="download-file-list">
                          <li>
                            <a href="../../../../assets/files/Informe Auditoría de Cuentas 2021.pdf" target="_blank">
                              <img src="../../../../assets/images/pdf.png" alt="image">
                                  <div class="title">
                                      <h4>Informe Auditoría de Cuentas 2021</h4>
                                      <span>PDF | 12 MB</span>
                                  </div>
                              </a>
                          </li>
                          <li>
                            <a href="../../../../assets/files/Informe Auditoría de Cuentas 2022.pdf" target="_blank">
                              <img src="../../../../assets/images/pdf.png" alt="image">
                                <div class="title">
                                    <h4>Informe Auditoría de Cuentas 2022</h4>
                                    <span>PDF | 13.9 MB</span>
                                </div>
                            </a>
                          </li>
                          <li>
                            <a href="../../../../assets/files/CCAA + Auditoria  Montepío 2023 firmadas.pdf" target="_blank">
                              <img src="../../../../assets/images/pdf.png" alt="image">
                                <div class="title">
                                    <h4>Informe Auditoría de Cuentas 2023</h4>
                                    <span>PDF | 18.6 MB</span>
                                </div>
                            </a>
                          </li>
                      </ul>
                    </div>

                </div>
            </aside>
          </div>
        }

        @if(order=='asamblea'){
          <div class="col-lg-12 col-md-12">
            <div class="img-asamblea row">
              <img src="../../../../assets/images/info-flyer.png" class="col-lg-6" alt="">
              <div class="col-lg-6">
                <h4>Convocatoria de la Asamblea General Ordinaria
                  del Montepío del Igualatorio Entidad de
                  Previsión Social Voluntaria de Empleo, EPSV</h4>
                  <h6>10 DE ABRIL DE 2025</h6>
                  <p>Se comunica la <b>convocatoria de la Asamblea General Ordinaria el 10 de abril de
                    2025</b> en primera convocatoria a las 17:30 horas y en segunda convocatoria el mismo
                    día a las 18:00 horas, en el salón de actos de la Clínica IMQ Zorrotzaurre de Bilbao</p>
              </div>

            </div>
            
          </div>
          <div class="col-lg-12 col-md-12">
            <aside class="widget-area">
              <div class="widget widget_download_file">
                <h1 class="widget-title">Documentación</h1>
                <div>
                  <h6>Asamblea general 2025</h6>
                  <ul class="download-file-list">
                      <li>
                        <a href="../../../../assets/files/Convocatoria asamblea.pdf" target="_blank">
                          <img src="../../../../assets/images/pdf.png" alt="image">
                              <div class="title">
                                  <h4>Convocatoria asamblea general ordinaria 2025</h4>
                                  <span>PDF | 87 KB</span>
                              </div>
                          </a>
                      </li>
                      <li>
                        <a href="../../../../assets/files/Propuesta de acuerdos-2025-03-25-2.pdf" target="_blank">
                          <img src="../../../../assets/images/pdf.png" alt="image">
                            <div class="title">
                                <h4>Propuesta de acuerdos</h4>
                                <span>PDF | 92 KB</span>
                            </div>
                        </a>
                      </li>
                      <li>
                        <a href="../../../../assets/files/Delegación Voto Asamblea.pdf" target="_blank">
                          <img src="../../../../assets/images/pdf.png" alt="image">
                            <div class="title">
                                <h4>Delegación del voto</h4>
                                <span>PDF | 119 KB</span>
                            </div>
                        </a>
                      </li>
                      
                  </ul>
                </div>

            </div>
            </aside>
          </div>
        }

        @if(order=='derechos'){
          <div class="col-lg-12 col-md-12">
            <aside class="widget-area">
                <div class="widget widget_download_file">
                    <h1 class="widget-title">Documentación</h1>
                    <div>
                      <h6>Principios de inversiones</h6>
                      <ul class="download-file-list">
                          <li>
                            <a href="../../../../assets/files/Declaracion principios de Inversion2024.pdf" target="_blank">
                              <img src="../../../../assets/images/pdf.png" alt="image">
                                  <div class="title">
                                      <h4>Declaración de Principios de Inversión 2024</h4>
                                      <span>PDF | 1.3 MB</span>
                                  </div>
                              </a>
                          </li>
                      </ul>
                    </div>

                </div>
            </aside>
          </div>
        }

        @if(order=='junta'){
          <div class="col-lg-12 col-md-12">
            <div class="info-desc">
              <h1>Composición de la Junta de Gobiero del Montepío del Igualatorio</h1>
              <div class="content">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Cargo</th>
                      <th scope="col">Nombre</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Presidente/a</td>
                      <td>MAGDALENA MUGICA PORTILLO</td>
                    </tr>
                    <tr>
                      <td>Vicepresidente</td>
                      <td>IÑIGO VILAR ACHABAL</td>
                    </tr>
                    <tr>
                      <td>Secretario/a no miembro</td>
                      <td>JOSE ORIOL PROSPER CARDOSO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>JOSE MARIA CASTAÑOS DEL MOLINO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>AINHOA RUBIO EREÑO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>AITOR BARAÑANO ABASOLO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>JESUS GORROCHATEGUI EREÑO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>MARIO PUGA BARREDO</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>MIGUEL ZALDÚA ARRESE</td>
                    </tr>
                    <tr>
                      <td>Vocal</td>
                      <td>MYRIAM BOYERO URANGA</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
      </div>
  </div>
</div>

