import { AfterViewChecked, AfterViewInit, Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from '../../../../core/services/common.service';
import { ApiService } from '../../../../core/services/api.service';
import { Aportacion } from '../../../../shared/models/aportacion.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TipoAportacion } from '../../../../shared/models/tipoAportacion.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver-es';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-my-contributions',
  templateUrl: './my-contributions.component.html',
  styleUrls: ['./my-contributions.component.scss'] // Note the 'styleUrls' instead of 'styleUrl'
})
export class MyContributionsComponent implements AfterViewInit, OnInit, AfterViewChecked {

  readonly range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  destroyRef = inject(DestroyRef)

  userName:string =''
  userNumber: number = 0

  selectForm: FormGroup;
  filtroTipo: string = '';
  filtroFecha: string = '';
  filtroRango: string = '';

  sortColumn: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';

  private logoBase64: string = '';

  private logoWidth: number = 0;  // Ancho original de la imagen
  private logoHeight: number = 0; // Alto original de la imagen

  constructor(
    public commonService: CommonService,
    public apiService: ApiService,
    private fb: FormBuilder,
  )
    {
    this.selectForm = this.fb.group({
    });
    this.loadImageAsBase64('  ../../../../../assets/images/montepio.png').then(base64 => {
      this.logoBase64 = base64;
      const img = new Image();
      img.src = base64;
      img.onload = () => {
        this.logoWidth = img.width;
        this.logoHeight = img.height;
      };
    });
  }

  aportaciones: Aportacion[] = [];
  tipoAportaciones: TipoAportacion[] = [];

  noAportaciones: Boolean = false;

  loaded: Boolean = false;
  displayedColumns: string[] = ['fecha_cobro', 'tipo', 'importe'];
  dataSource = new MatTableDataSource<any>();


  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit(): void {
    this.commonService.espacioComponente();
    this.getData();
    this.userName = JSON.parse(localStorage.getItem("currentUser") || '').name
    this.userName = this.userName.toLowerCase().replace(/(?:^|\s)\S/g, match => match.toUpperCase())
    this.commonService.nombre = this.userName

    // Escuchar cambios en el rango de fechas
    this.range.valueChanges.subscribe(() => {
      this.applyDateFilter();  // Aplica el filtro cuando cambia el rango de fechas
    });
  }

  ngAfterViewInit() {
    const delay = 0; // <= eso es ser rapido
    setTimeout(() => {
      this.commonService.espacioComponente();
      this.dataSource.paginator = this.paginator;

    }, delay);
  }

  ngAfterViewChecked() {
    if (this.dataSource && this.paginator && !this.dataSource.paginator) {
      this.dataSource.paginator = this.paginator;

      const componentPaginator = document.querySelector(".mat-mdc-paginator-range-label")
      if (componentPaginator && componentPaginator instanceof HTMLElement){
        if(window.innerWidth<767){
          componentPaginator.style.display='none'

        }

      }

      const component = document.getElementById('select');
          if (component){
            const primerElemento = component.children[0]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
            if (primerElemento && primerElemento instanceof HTMLElement){
              primerElemento.style.backgroundColor = 'white';
            }
            const segundoElemento = component.children[1]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
            if (segundoElemento && segundoElemento instanceof HTMLElement){
              segundoElemento.style.display = 'none';
            }
          }

      const component2 = document.getElementById('range-date');
          if (component2){
            const primerElemento = component2.children[0]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
            if (primerElemento && primerElemento instanceof HTMLElement){
              primerElemento.style.backgroundColor = 'white';
            }
            const segundoElemento = component2.children[1]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
            if (segundoElemento && segundoElemento instanceof HTMLElement){
              segundoElemento.style.display = 'none';
            }
          }

    }
  }

  cambioScroll(){
    const componentTipo = document.querySelector('[role="listbox"]');
    if (componentTipo && componentTipo instanceof HTMLElement){
      componentTipo.style.fontSize='small'
      componentTipo.style.height = 'auto';
      componentTipo.style.maxHeight = 'none';
    }
  }

  applyFilter(filterValue: string | null) {
    const { start, end } = this.range.value;

    this.dataSource = new MatTableDataSource<any>(this.aportaciones.map(({ fecha_cobro, importe, tipo }) => ({ fecha_cobro, importe, tipo: tipo.nombre })));
    this.filtroFecha=''
    if (filterValue !== null) {
      if(this.filtroTipo){
        this.applyTipoFilter(this.filtroTipo)
        this.dataSource.data = this.dataSource.filteredData
      } else{
        this.dataSource = new MatTableDataSource<any>(this.aportaciones.map(({ fecha_cobro, importe, tipo }) => ({ fecha_cobro, importe, tipo: tipo.nombre })));
      }
      this.filtroFecha = filterValue.trim().toLowerCase();
      this.dataSource.filter = this.filtroFecha
    } else {
      this.dataSource = new MatTableDataSource<any>(this.aportaciones.map(({ fecha_cobro, importe, tipo }) => ({ fecha_cobro, importe, tipo: tipo.nombre })));
      this.dataSource.filter = '';
      this.filtroFecha = ''
      this.applyTipoFilter(this.filtroTipo)
    }

    if(start && end){
      this.dataSource = new MatTableDataSource<any>(
        this.dataSource.filteredData.filter((aportacion) => {
          const fechaPago = new Date(aportacion.fecha_cobro);
          return fechaPago >= start && fechaPago <= end;
        }).map(({ fecha_cobro, importe, tipo }) => ({
          fecha_cobro,
          importe,
          tipo
        }))
      );
    }
  }

  applyTipoFilter(tipo: string | null) {
    const { start, end } = this.range.value;

    this.dataSource = new MatTableDataSource<any>(this.aportaciones.map(({ fecha_cobro, importe, tipo }) => ({ fecha_cobro, importe, tipo: tipo.nombre })));
    this.filtroTipo=''
    if ((tipo !== null) && (tipo!=='all')) {
      if(this.filtroFecha){
        this.applyFilter(this.filtroFecha)
        this.dataSource.data = this.dataSource.filteredData
      } else{
        this.dataSource = new MatTableDataSource<any>(this.aportaciones.map(({ fecha_cobro, importe, tipo }) => ({ fecha_cobro, importe, tipo: tipo.nombre })));
      }
      this.filtroTipo = tipo.trim().toLowerCase();
      this.dataSource.filter = this.filtroTipo
    } else {
      this.dataSource = new MatTableDataSource<any>(this.aportaciones.map(({ fecha_cobro, importe, tipo }) => ({ fecha_cobro, importe, tipo: tipo.nombre })));
      this.dataSource.filter = '';
      this.filtroTipo = ''
      this.applyFilter(this.filtroFecha)
    }

    if(start && end){
      this.dataSource = new MatTableDataSource<any>(
        this.dataSource.filteredData.filter((aportacion) => {
          const fechaPago = new Date(aportacion.fecha_cobro);
          return fechaPago >= start && fechaPago <= end;
        }).map(({ fecha_cobro, importe, tipo }) => ({
          fecha_cobro,
          importe,
          tipo
        }))
      );
    }
  }

  applyDateFilter() {
    const { start, end } = this.range.value;
    this.applyFilter(this.filtroFecha)
    this.applyTipoFilter(this.filtroTipo)


    if(start && end){
      this.dataSource = new MatTableDataSource<any>(
        this.dataSource.filteredData.filter((aportacion) => {
          const fechaPago = new Date(aportacion.fecha_cobro);
          return fechaPago >= start && fechaPago <= end;
        }).map(({ fecha_cobro, importe, tipo }) => ({
          fecha_cobro,
          importe,
          tipo
        }))
      );
    }
  }


  // Método para formatear el importe
  formatCurrency(value: number): string {
    if (value == null) {
      return '';
    }

    // Convertir el número a una cadena con dos decimales
    const parts = value.toFixed(2).split('.');

    // Formatear la parte entera con puntos como separadores de miles
    const integerPart = parts[0];
    const decimalPart = parts[1];

    // Insertar los puntos en la parte entera
    const integerFormatted = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Formar el resultado final con coma como separador decimal
    return `${integerFormatted},${decimalPart} €`;
  }


  getData() {
    this.apiService.getAportaciones().pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: (res) => {
        if (res) {
          this.aportaciones = res;
          if(this.aportaciones.length==0){
            this.noAportaciones=true
          }
          this.dataSource = new MatTableDataSource<any>(this.aportaciones.map(({ fecha_cobro, importe, tipo }) => ({ fecha_cobro, importe, tipo: tipo.nombre })));
          this.loaded = true;
        } else {
          console.warn('Empty response received');
        }
      },
      error: (error) => {
        console.error('Error fetching aportaciones: ', error);
      },
    });
    this.apiService.getTipoAportaciones().pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: (res) => {
        if (res) {
          this.tipoAportaciones = res;
        } else {
          console.warn('Empty response received');
        }
      },
      error: (error) => {
        console.error('Error fetching aportaciones: ', error);
      },
    });
  }

  sortData(column: string) {
    const isAsc = this.sortColumn === column && this.sortDirection === 'asc';
    this.sortDirection = isAsc ? 'desc' : 'asc';
    this.sortColumn = column;

    this.dataSource.data = this.dataSource.filteredData.map(({ fecha_cobro, importe, tipo }) => ({ fecha_cobro, importe, tipo })).sort((a, b) => {
      const isAsc = this.sortDirection === 'asc';
      switch (column) {
        case 'fecha_cobro': return this.compare(a.fecha_cobro, b.fecha_cobro, isAsc);
        case 'tipo': return this.compare(a.tipo, b.tipo, isAsc);
        case 'importe': return this.compare(a.importe, b.importe, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string | undefined, b: number | string | undefined, isAsc: boolean) {
    if (a === undefined || b === undefined) return 0;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  private loadImageAsBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  exportarAExcel() {
    // Convertir los datos de la tabla a un formato adecuado para Excel
    const datosFiltrados = this.dataSource.filteredData.map(({ fecha_cobro, importe, tipo }) => ({
      'Fecha de Cobro': fecha_cobro,
      'Tipo de Aportación': tipo,
      'Importe': importe
    }));

    // Agregar una fila para el total al final
    datosFiltrados.push({
      'Fecha de Cobro': '', // La celda de fecha vacía
      'Tipo de Aportación': 'TOTAL:', // Texto "Total" en la columna de Tipo de Prestación
      'Importe': '' // Se deja vacío porque se llenará con la fórmula de Excel
    });


    // Crear una hoja de cálculo
    const hojaDeCalculo: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datosFiltrados);

    // Ajustar el ancho de las columnas
    const columnas = [
      { header: 'Fecha de Cobro', key: 'Fecha de Cobro', width: 20 },
      { header: 'Tipo de Aportación', key: 'Tipo de Aportación', width: 25 },
      { header: 'Importe', key: 'Importe', width: 15 }
    ];

    hojaDeCalculo['!cols'] = columnas.map(col => ({ width: col.width }));

    // Aplicar formato de moneda a la columna 'Importe'
    const formatoMoneda = { numFmt: '#,##0.00 €' }; // Formato de moneda en euros con símbolo al final
    const columnaImporte = 'C'; // La columna 'Importe' es la C en la hoja de cálculo

    // Aplica el formato de moneda a todas las celdas en la columna 'Importe'
    for (let i = 1; i <= datosFiltrados.length; i++) {
      const celda = `${columnaImporte}${i + 1}`; // i+1 para ignorar el encabezado
      hojaDeCalculo[celda] = {
        ...(hojaDeCalculo[celda] || {}),
        z: formatoMoneda.numFmt
      };
    }

        // Obtener el número de filas de datos (sin contar el encabezado)
        const totalRows = datosFiltrados.length;

        // Insertar la fórmula de suma en la última fila de la columna 'Importe'
        const celdaTotal = `${columnaImporte}${totalRows + 1}`; // Celda para la suma (después de los datos)
        const rangoImportes = `${columnaImporte}2:${columnaImporte}${totalRows}`; // Rango de celdas a sumar (sin encabezado)
        hojaDeCalculo[celdaTotal] = { f: `SUM(${rangoImportes})` }; // Insertar la fórmula de suma


        // Aplicar formato de moneda a la celda del total
        hojaDeCalculo[celdaTotal] = {
          ...(hojaDeCalculo[celdaTotal] || {}),
          z: formatoMoneda.numFmt // Aplicar formato de moneda
        };

    // Crear un libro de trabajo y agregar la hoja
    const libroDeTrabajo: XLSX.WorkBook = {
      Sheets: { 'Aportaciones': hojaDeCalculo },
      SheetNames: ['Aportaciones']
    };

    // Generar el archivo Excel en formato binario
    const excelBuffer: any = XLSX.write(libroDeTrabajo, { bookType: 'xlsx', type: 'array' });

    // Guardar el archivo Excel
    this.guardarComoExcel(excelBuffer, 'Aportaciones');
  }

  private guardarComoExcel(buffer: any, nombreArchivo: string) {
    const blob: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `${nombreArchivo}.xlsx`);
  }

  exportarAPDF() {
    const doc = new jsPDF();

    const addHeader = () => {
      if (this.logoBase64) {
        // Utilizar el tamaño original de la imagen
        doc.addImage(this.logoBase64, 'PNG', 14, 10, this.logoWidth * 0.2, this.logoHeight * 0.2); // Ajusta el tamaño según sea necesario
      }
      doc.setFontSize(12);
      doc.setTextColor(40);

    };

    const addFooter = (data:any)=> {
      const footerText = 'Página ' + data.pageNumber;
      const pageWidth = doc.internal.pageSize.width;
      const margin = 10; // Margen desde el borde de la página

      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.text(footerText, pageWidth - margin - 20, doc.internal.pageSize.height - margin);
    }

    // Función para agregar encabezado a cada página
    const addHeaderToEachPage = (data: any) => {
      addHeader();
      addFooter(data);

    };

    // Configuración de alineación de columnas
    const columnStyles = {
      2: { // Columna 2 (Importe)
        halign: 'right' // Alinear a la derecha
      }
    };

    if(this.userNumber===0){
      const user = localStorage.getItem('currentUser')
      if(user){
        this.userNumber = JSON.parse(user).number
      }
    }

    // Título del documento
    doc.text('Aportaciones - ' + this.userName + ' ('+this.userNumber + ')', 14, 40);

    // Obtener los datos filtrados
    const datosFiltrados = this.dataSource.filteredData.map(({ fecha_cobro, importe, tipo }) => [
      fecha_cobro,
      tipo,
      this.formatCurrency(importe)
    ]);

     // Calcular el importe total
     const totalImporte = this.dataSource.filteredData.reduce((acc, { importe }) => acc + importe, 0);
     const totalImporteFormatted = this.formatCurrency(totalImporte);


    // Configuración de las columnas
    const encabezados = [['Fecha de Cobro', 'Tipo de Aportación', { content: 'Importe', styles: { halign: 'right' } }]];
    // Agregar la tabla al PDF
    (doc as any).autoTable({
      startY: 45,
      didDrawPage: addHeaderToEachPage,
      head: encabezados,
      body: datosFiltrados,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [29, 82, 115] }, // Color de la cabecera
      columnStyles: columnStyles, // Aplicar estilos de columna
      theme: 'striped', // Estilo de la tabla
      margin: { top: 45 } // Margen superior de 50
    });

       // Obtener la posición actual de Y después de la tabla
       const finalY = (doc as any).lastAutoTable.finalY || 45;

       // Calcular la posición en el eje X (derecha)
      const pageWidth = doc.internal.pageSize.width;
      const marginRight = 14; // Margen derecho
      const totalX = pageWidth - marginRight;

      // Agregar el total después de la tabla
      doc.setFontSize(10);
      doc.text(`TOTAL: ${totalImporteFormatted}`, totalX, finalY + 10, { align: 'right' });


    // Guardar el PDF
    doc.save('Aportaciones.pdf');
  }

  // SEGUNDA OPCION (mal)

  // exportarAPDF(): void {
  //   // Obtener los datos filtrados
  //   const datosFiltrados = this.dataSource.filteredData.map(({ fecha_cobro, importe, tipo }) => ({
  //     'Fecha de Cobro': fecha_cobro,
  //     'Tipo de Aportación': tipo,
  //     'Importe': this.formatCurrency(importe)
  //   }));

  //   // Crear la definición del documento
  //   const docDefinition: TDocumentDefinitions = {
  //     content: [
  //       { text: 'Aportaciones', style: 'header' },
  //       {
  //         table: {
  //           headerRows: 1,
  //           widths: ['*', '*', '*'],
  //           body: [
  //             ['Fecha de Cobro', 'Tipo de Aportación', 'Importe'], // Encabezados
  //             ...datosFiltrados.map(item => [item['Fecha de Cobro'], item['Tipo de Aportación'], item['Importe']])
  //           ]
  //         },
  //         layout: 'lightHorizontalLines' // Estilo de la tabla
  //       }
  //     ],
  //     styles: {
  //       header: {
  //         fontSize: 18,
  //         bold: true,
  //         margin: [0, 0, 0, 10]
  //       }
  //     }
  //   };

  //   // Generar el PDF
  //   pdfMake.createPdf(docDefinition).download('Aportaciones.pdf');
  // }

}
