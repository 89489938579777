<!-- Page Banner -->
<!-- <div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-banner-content">
            <h2>password_lost</h2>
            <ul class="pages-list">
                <li><a href="index.html">Home</a></li>
                <li>password_lost</li>
            </ul>
        </div>
    </div>
</div> -->

<!-- password_lost -->
<div class="password_lost-area password_lost-background">
  <div class="container"     >
      <div class="password_lost-form">
          <h4>Recuperar contraseña</h4>
          
          @if(validToken){
            <p>Introduce el dni y la contraseña nueva que desea. Recuerde que debe tener un mínimo de 6 caracteres.</p>

            <form [formGroup]="recover2Form">
              <div class="form-group">
                <label>DNI</label>
                <input type="text" required  class="form-control" formControlName="dni" [(ngModel)]="dni" placeholder="dni" name="DNI" >
                <div *ngIf="recover2Form.get('dni')?.errors && recover2Form.get('dni')?.touched" class="text-danger">
                  <div *ngIf="recover2Form.get('dni')?.hasError('required')">Campo requerido</div>
                </div>
              </div>
                <div class="form-group">
                  <label>Contraseña nueva</label>
                  <input type="password" required  class="form-control" formControlName="password" [(ngModel)]="password" placeholder="Password" name="password" >
                  <div *ngIf="recover2Form.get('password')?.errors && recover2Form.get('password')?.touched" class="text-danger">
                    <div *ngIf="recover2Form.get('password')?.hasError('required')">Campo requerido</div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Repetir contraseña</label>
                  <input type="password" required  class="form-control" formControlName="password2" [(ngModel)]="password2" placeholder="Password" name="password2" >
                  <div *ngIf="recover2Form.get('password2')?.errors && recover2Form.get('password2')?.touched" class="text-danger">
                    <div *ngIf="recover2Form.get('password2')?.hasError('required')">Campo requerido</div>
                  </div>
                </div>
              <div *ngIf="errorMessage" class="error-message col-lg-8 col-md-6">
                {{ errorMessage }}
              </div>
                <button type="submit" class="default-btn" [disabled]="!dni || !password || !password2 || !recover2Form.valid" (click)="recoverPassword2()">Recuperar</button>
                <!-- <div class="account-text">
                    <span><a routerLink="/register">Registrarse</a></span>
                </div> -->
            </form>
          } @else{
            <p>Url incorrecta</p>
          }
      </div>
  </div>
</div>


<div class="video-popup" *ngIf="isOpen2">
  <div class="popup-inner">
    <h5>Contraseña actualizada</h5>
    <p>Se ha cambiado la contraseña correctamente</p>
    <div class="close-button-container">
      <button (click)="closePopup2()" class="popup-btn" type="button">Cerrar</button>
    </div>
  </div>
</div>
