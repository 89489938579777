import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';



@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {

        if (err instanceof HttpErrorResponse) {
          // Handle HTTP errors
          if (err.status === 401) {
            // Specific handling for unauthorized errors
            console.error('Unauthorized request:', err);
            this.authenticationService.logout();
            // You might trigger a re-authentication flow or redirect the user here
          } else {
            // Handle other HTTP error codes
            console.error('HTTP error:', err);
          }
        } else {
          // Handle non-HTTP errors
          console.error('An error occurred:', err);
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}


// export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
//   return next(req).pipe(
//     catchError((err: any) => {
//       if (err instanceof HttpErrorResponse) {
//         // Handle HTTP errors
//         if (err.status === 401) {
//           // Specific handling for unauthorized errors
//           console.error('Unauthorized request:', err);
//           // You might trigger a re-authentication flow or redirect the user here
//         } else {
//           // Handle other HTTP error codes
//           console.error('HTTP error:', err);
//         }
//       } else {
//         // Handle non-HTTP errors
//         console.error('An error occurred:', err);
//       }

//       // Re-throw the error to propagate it further
//       return throwError(() => err);
//     })
//   );

// };
