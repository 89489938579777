import { Situacion } from "./situacion.model"
import { TipoPrestacion } from "./tipoPrestacion.model"

import { Injectable } from '@angular/core';
import { Empresa } from "./empresa.model";

@Injectable({
  providedIn: 'root'
})

export class User{

  id: number
  situacion: Situacion
  tipo: TipoPrestacion
  empresa: Empresa
  codigo: String
  numero: number
  dni: string
  nombre: string
  apellido1: string
  apellido2: string
  fnac: string
  genero: string
  direccion: string
  cp: string
  poblacion: string
  provincia: string
  alta: string
  baja: string
  motivo_baja: string
  discapacidad: string
  tel1: string
  tel2: string
  email: string
  iban: string
  bic: string
  mandato: string
  activo_web: string
  jubilacion: string


  constructor() {
    this.id = 0
    this.situacion = new Situacion()
    this.tipo = new TipoPrestacion()
    this.empresa = new Empresa()
    this.codigo = ''
    this.numero = 0
    this.dni = ''
    this.nombre = ''
    this.apellido1 = ''
    this.apellido2 = ''
    this.fnac = ''
    this.genero = ''
    this.direccion = ''
    this.cp = ''
    this.poblacion = ''
    this.provincia = ''
    this.alta = ''
    this.baja = ''
    this.motivo_baja = ''
    this.discapacidad = ''
    this.tel1 = ''
    this.tel2 = ''
    this.email = ''
    this.iban = ''
    this.bic = ''
    this.mandato = ''
    this.activo_web = ''
    this.jubilacion = ''
  }

 }
