import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  nombre: string = ''

  constructor() { }

  isValidSpanishDNI(dni: string): boolean {
    dni = dni.toUpperCase()
    const dniRegex = /^\d{8}[A-Z]$/; // 8 digits followed by a letter
    if (!dniRegex.test(dni)) {
      return false;
    }

    const numberPart = parseInt(dni.slice(0, 8), 10);
    const letterPart = dni.charAt(8);
    const validLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const expectedLetter = validLetters.charAt(numberPart % 23);

    return letterPart === expectedLetter;
  }


  espacioComponente(){
    // Aquí puedes acceder al elemento padre y manipular su segundo hijo
    const contenedor = document.getElementById('componente')

    // Aplicando estilos al segundo hijo
    if (contenedor && contenedor.children.length >= 2) {
      const segundoHijo = contenedor.children[1] as HTMLElement; // Type assertion
      segundoHijo.style.flex = '1';
      segundoHijo.style.display='flex';
      segundoHijo.style.flexDirection='column';

      segundoHijo.classList.add('componenteRenderizado');
      // Puedes aplicar cualquier estilo aquí según tus necesidades
    }
  }

}
