import { ApiService } from './../../../core/services/api.service';
import { CookieService } from './../../../core/services/cookie.service';
import { AfterViewInit, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';
import { User } from '../../../shared/models/user.model';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';



@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss'],
})
export class UserDataComponent implements OnInit, AfterViewInit {

  destroyRef = inject(DestroyRef)

  userData:  User;
  userName: string = '';
  loaded: Boolean = false;
  password: string = '';
  new_password: string = '';
  repeat_password: string = '';
  errorMessage: string = '';
  nombreBanco: string = ''

  constructor(
    public commonService: CommonService,
    public apiService: ApiService,
  ) {
    this.userData = new User()
  }

  currentDate: string = '';

  ngOnInit(): void {
    this.currentDate = this.obtenerFechaActual()
    this.commonService.espacioComponente()
    this.getData()
    this.userName = JSON.parse(localStorage.getItem("currentUser") || '').name
    this.userName = this.userName.toLowerCase().replace(/(?:^|\s)\S/g, match => match.toUpperCase())
    this.commonService.nombre = this.userName
  }

  ngAfterViewInit() {
    const delay = 0; // <= eso es ser rapido
    setTimeout(() => {
      this.commonService.espacioComponente()
     }, delay);

  }

  obtenerFechaActual(): string {
    const hoy = new Date();
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    return new Intl.DateTimeFormat('es-ES', options).format(hoy);
  }
  getBancoNombre(iban: string): string {
    const bancoCode = iban.substring(4, 8);
    return this.bancoCodes[bancoCode];
  }

  getData(){
    this.apiService.getData().pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          if (res) {
            this.userData=res
            if(this.userData.discapacidad=='N'|| this.userData.discapacidad=='n'){
              this.userData.discapacidad='NO'
            }
            if(this.userData.discapacidad=='S'|| this.userData.discapacidad=='s') {
              this.userData.discapacidad='SI'
            }
            this.nombreBanco = this.getBancoNombre(this.userData.iban);


            if(this.userData.genero=='M'|| this.userData.genero=='m'){
              this.userData.genero='Masculino'
            }
            if(this.userData.genero=='F'|| this.userData.genero=='f'){
              this.userData.genero='Femenino'
            }
            this.loaded = true

          } else {

          }
        },
        error: (error) => {

        },
      });
  }
  isOpen = false;
  openPopup(): void {
      this.isOpen = true;
  }
  closePopup(): void {
      this.isOpen = false;
  }

  private bancoCodes: { [key: string]: string } = {
    '0185': 'Banco Urquijo Sabadell Banca Privada, S.A.',
    '0112': 'Banco Urquijo, S.A.',
    '0075': 'Banco Popular Español, S.A.',
    '2066': 'Caja De Ahorros De Santander Y Cantabria',
    '0241': 'A&G BANCO, S.A.',
    '2038': 'Bankia, S.A',
    '2080': 'Abanca Corporacion Bancaria, S.A.',
    '8620': 'Abanca Servicios Financieros E.F.C., S.A.',
    '1535': 'AKF Bank GmbH & Co Kg, Sucursal en España',
    '0011': 'Allfunds Bank, S.A.',
    '0200': 'ANDBANK ESPAÑA BANCA PRIVADA, S.A.',
    '0138': 'Bankoa, S.A.',
    '0125': 'Bancofar, S.A.',
    '0136': 'Aresbank, S.A.',
    '3183': 'Arquia Bank, S.A.',
    '1541': 'Attijariwafa Bank Europe, Sucursal en España',
    '0061': 'Banca March, S.A.',
    '0065': 'Barclays Bank, S.A.',
    '1550': 'Banca Popolare Etica Società Cooperativa per Azioni, Sucursal en España',
    '0078': 'Banca Pueyo, S.A.',
    '0188': 'Banco Alcalá, S.A.',
    '0182': 'Banco Bilbao Vizcaya Argentaria, S.A.',
    '0225': 'Banco Cetelem, S.A.',
    '0198': 'Banco Cooperativo Español, S.A.',
    '0091': 'Banco de Albacete, S.A.',
    '0240': 'Banco de Crédito Social Cooperativo',
    '0003': 'Banco de Depósitos, S.A.',
    '9000': 'BANCO DE ESPAÑA',
    '1569': 'Banco de Investimento Global SA, Sucursal en España',
    '0169': 'Banco de la Nación Argentina, Sucursal en España',
    '0081': 'Banco de Sabadell, S.A.',
    '0086': 'Banco Banif, S.A.',
    '0233': 'Popular Banca Privada, S.A.',
    '0184': 'Banco Europeo de Finanzas, S.A.',
    '0220': 'BANCO FINANTIA, S.A., SUCURSAL EN ESPAÑA',
    '0232': 'Banco Inversis, S.A.',
    '0186': 'Banco Mediolanum, S.A.',
    '0121': 'Banco Occidental, S.A.',
    '0235': 'Banco Pichincha España, S.A..',
    '1509': 'Banco Primus, S.A., Sucursal en España',
    '0042': 'Banco Guipuzcoano, S.A.',
    '0049': 'Banco Santander, S.A.',
    '8843': 'BANGE CREDIT E.F.C. SA',
    '1574': 'Bank Julius Baer Europe, S.A, Sucursal en España',
    '0219': 'Bank of Africa Europe, S.A.',
    '1485': 'Bank of America Europe DAC, Sucursal en España',
    '1488': 'BANK PICTET & CIE (EUROPE) AG SUCURSAL EN ESPAÑA',
    '8832': 'Bankinter Consumer Finance, E.F.C., S.A.',
    '0128': 'Bankinter, S.A.',
    '1525': 'Banque Chaabi du Maroc, Sucursal en España',
    '1580': 'BANQUE J. SAFRA SARASIN (LUXEMBOURG) SA, SUCURSAL EN ESPAÑA',
    '0152': 'Barclays Bank Ireland PLC, Sucursal en España',
    '1554': 'BFF Bank, S.P.A. Sucursal en España',
    '6712': 'BIP&Drive EDE, S.A',
    '1533': 'BMW Bank GmbH, Sucursal en España',
    '6717': 'Bnext Electronic Issuer, E.D.E. S.L.',
    '1532': 'BNP Paribas Factor, Sucursal en España',
    '0167': 'BNP Paribas Fortis, S.A., N.V., Sucursal en España',
    '1492': 'BNP Paribas Lease Group S.A. Sucursal en España',
    '0149': 'BNP Paribas S.A., Sucursal en España',
    '1500': 'BPCE Lease, Sucursal en España',
    '1576': 'Bunq B.V., Sucursal en España',
    '8640': 'CA AUTO BANK, SPA, SUCURSAL EN ESPAÑA',
    '1545': 'CA Indosuez Wealth (Europe), Sucursal en España',
    '0038': 'CACEIS Bank Spain, S.A.',
    '1451': 'Caisse Régionale de Crédit Agricole Mutuel Sud-Méditerranée (Ariège et Pyrénées-Orientales)',
    '0130': 'Banco Caixa Geral, S.A.',
    '0131': 'Banco Espirito Santo, S.A., Sucursal En España',
    '1493': 'Caixa Banco de Investimento, S.A. Sucursal en España',
    '3025': 'Caixa de Crédit dels Enginyers- Caja de Crédito de los Ingenieros, S. Coop. de Crédito',
    '3159': 'Caixa Popular-Caixa Rural, S. Coop. de Crédito V.',
    '3045': 'Caixa Rural Altea, Cooperativa de Crédito Valenciana',
    '3162': 'Caixa Rural Benicarló, S. Coop. de Credit V.',
    '3117': 'Caixa Rural D Algemesí, S. Coop. V. de Crédit',
    '3105': 'Caixa Rural de Callosa d en Sarrià, Cooperativa de Crédito Valenciana',
    '3096': 'Caixa Rural de L Alcudia, Sociedad Cooperativa Valenciana de  Crédito',
    '3123': 'Caixa Rural de Turís, Cooperativa de Crédito Valenciana',
    '3070': 'Caixa Rural Galega, Sociedad Cooperativa de Crédito Limitada Gallega',
    '3111': 'Caixa Rural La Vall San Isidro, Sociedad Cooperativa de Crédito Valenciana',
    '3166': 'Caixa Rural Les Coves de Vinromá, S. Coop. de Crédit V.',
    '3160': 'Caixa Rural Sant Josep de Vilavella, S. Coop. de Crédito V.',
    '3102': 'Caixa Rural Sant Vicent Ferrer de La Vall D Uixó, Coop. de Credit V.',
    '3118': 'Caixa Rural Torrent Cooperativa de Credit Valenciana',
    '3174': 'Caixa Rural Vinarós, S. Coop. de Credit. V.',
    'FI2680': 'CAIXABANK MONETARIO RENDIMIENTO, FI',
    '8776': 'Caixabank Payments & Consumer, E.F.C., E.P., S.A.',
    '2100': 'Caixabank, S.A.',
    '2101': 'Caja De Ahorros Y Monte De Piedad De Gipuzkoa Y San Sebastian',
    '2045': 'Caja de Ahorros y Monte de Piedad de Ontinyent',
    '3029': 'Caja de Crédito de Petrel, Caja Rural, Cooperativa de Crédito Valenciana',
    '3035': 'Caja Laboral Popular Coop. de Crédito',
    '3115': 'Caja Rural Nuestra Madre del Sol, S. Coop. Andaluza de Crédito',
    '3110': 'Caja Rural Católico Agraria, S. Coop. de Crédito V.',
    '3005': 'Caja Rural Central, Sociedad Cooperativa de Crédito',
    '3190': 'Caja Rural de Albacete, Ciudad Real y Cuenca, Sociedad Cooperativa de Crédito',
    '3150': 'Caja Rural de Albal, Coop. de Crédito V.',
    '3179': 'Caja Rural de Alginet, Sociedad Cooperativa Crédito Valenciana',
    '3001': 'Caja Rural de Almendralejo, Sociedad Cooperativa de Crédito',
    '3191': 'Caja Rural de Aragón, Sociedad Cooperativa de Crédito',
    '3059': 'Caja Rural de Asturias, Sociedad Cooperativa de Crédito',
    '3084': 'Ipar Kutxa Rural, S. Coop. De Credito',
    '3089': 'Caja Rural de Baena Ntra. Sra. de Guadalupe Sociedad Cooperativa de Crédito Andaluza',
    '3060': 'Caja Rural de Burgos, Fuentepelayo, Segovia y Castelldans, Sociedad Cooperativa de Crédito',
    '3104': 'Caja Rural de Cañete de Las Torres Ntra. Sra. del Campo Sociedad Cooperativa Andaluza de Crédito',
    '3127': 'Caja Rural de Casas Ibáñez, S. Coop. de Crédito de Castilla-La Mancha',
    '3121': 'Caja Rural de Cheste, Sociedad Cooperativa de Crédito Valenciana',
    '3009': 'Caja Rural de Extremadura, Sociedad Cooperativa de Crédito',
    '3007': 'Caja Rural de Gijón, Sociedad Cooperativa Asturiana de Crédito',
    '3023': 'Caja Rural de Granada, Sociedad Cooperativa de Crédito',
    '3140': 'Caja Rural de Guissona, Sociedad Cooperativa de Crédito',
    '3067': 'Caja Rural de Jaén, Barcelona y Madrid, Sociedad Cooperativa de Crédito',
    '3008': 'Caja Rural de Navarra, S. Coop. de Crédito',
    '3098': 'Caja Rural de Nueva Carteya,  Sociedad Cooperativa Andaluza de Crédito',
    '3016': 'Caja Rural de Salamanca, Sociedad Cooperativa de Crédito',
    '3017': 'Caja Rural de Soria, Sociedad Cooperativa de Crédito',
    '3080': 'Caja Rural de Teruel, Sociedad Cooperativa de Crédito',
    '3020': 'Caja Rural de Utrera, Sociedad Cooperativa Andaluza de Crédito',
    '3144': 'Caja Rural de Villamalea, S. Coop. de Crédito Agrario de Castilla-La Mancha',
    '3152': 'Caja Rural de Villar, Coop. de Crédito V.',
    '3085': 'Caja Rural de Zamora, Cooperativa de Crédito',
    '3187': 'Caja Rural del Sur, S. Coop. de Crédito',
    '3157': 'Caja Rural La Junquera de Chilches, S. Coop. de Crédito V.',
    '3134': 'Caja Rural Nuestra Señora de la Esperanza de Onda, S. Coop. de Crédito V.',
    '3018': 'Caja Rural Regional San Agustín Fuente Álamo Murcia, Sociedad Cooperativa de Crédito',
    '3165': 'Caja Rural San Isidro de Vilafames S. Coop. de Crédito V.',
    '3119': 'Caja Rural San Jaime de Alquerías Niño Perdido S. Coop. de Crédito V.',
    '3113': 'Caja Rural San José de Alcora S. Coop. de Crédito V.',
    '3130': 'Caja Rural San José de Almassora, S. Coop. de Crédito V.',
    '3112': 'Caja Rural San José de Burriana, S. Coop. de Crédito V.',
    '3135': 'Caja Rural San José de Nules S. Coop. de Crédito V.',
    '3095': 'Caja Rural San Roque de Almenara S. Coop. de Crédito  V.',
    '3058': 'Cajamar Caja Rural, Sociedad Cooperativa de Crédito',
    '3076': 'Cajasiete, Caja Rural, Sociedad Cooperativa de Crédito',
    '0237': 'Cajasur Banco, S.A.',
    '8844': 'CASTELO CAPITAL EFC SA',
    '4706': 'Caterpillar Financial Corporación Financiera, S.A., E.F.C.',
    '0234': 'CBNK BANCO DE COLECTIVOS, S.A.',
    '2000': 'Cecabank, S.A.',
    '1553': 'China Construction Bank (Europe), S.A., Sucursal en España',
    '1474': 'Citibank Europe Plc, Sucursal en España',
    '1499': 'Claas Financial Services, S.A.S., Sucursal en España',
    '1546': 'CNH Industrial Capital Europe, S.A.S., Sucursal en España',
    '1543': 'Cofidis, S.A. Sucursal en España',
    '2056': 'Colonya - Caixa D estalvis de Pollensa',
    '0159': 'Commerzbank Aktiengesellschaft, Sucursal en España',
    '1459': 'Cooperatieve Rabobank U.A., Sucursal en España',
    '8221': 'Corporación Hipotecaria Mutual, S.A., E.F.C.',
    '8841': 'CREDIT AGRICOLE CONSUMER FINANCE SPAIN, EFC, S.A.',
    '0154': 'Crédit Agricole Corporate and Investment Bank, Sucursal en España',
    '1472': 'Crédit Agricole Leasing & Factoring, Sucursal en España',
    '1555': 'Crédit Mutuel Leasing, Sucursal en España',
    '0243': 'Credit Suisse Bank (Europe), S.A.',
    '6716': 'Currencies Direct Spain, EDE, SL',
    '8842': 'DAIMLER TRUCK FINANCIAL SERVICES ESPAÑA E.F.C., S.A.U.',
    '1457': 'De Lage Landen International B.V., Sucursal en España',
    '1548': 'Dell Bank International Designated Activity Company, Sucursal en España',
    '0145': 'Deutsche Bank, A.G., Sucursal en España',
    '0019': 'Deutsche Bank, Sociedad Anónima Española',
    '8826': 'Deutsche Leasing Iberica, E.F.C.,  S.A.',
    '1501': 'Deutsche Pfandbriefbank AG, Sucursal en España',
    '0211': 'EBN Banco de Negocios, S.A.',
    '1473': 'Edmond de Rothschild (Europe), Sucursal en España',
    '3081': 'Eurocaja Rural, Sociedad Cooperativa de Crédito',
    '0239': 'Evo Banco S.A.',
    '0218': 'FCE Bank Plc Sucursal en España',
    '8308': 'Financiera Carrión, S.A., E.F.C.',
    '8805': 'Financiera El Corte Inglés E.F.C., S.A.',
    '8823': 'Financiera española de crédito a distancia, E.F.C., S.A.',
    '6726': 'FINOM PAYMENTS SUCURSAL EN ESPAÑA',
    '6718': 'FINPAY Entidad de Dineero Electrónico, S.A',
    'FI5769': 'FONDO NARANJA MONETARIO, FI',
    '8839': 'GCC Consumo, EFC, S.A',
    '1496': 'Genefim, Sucursal en España',
    '6702': 'Global Payment Moneytopay, EDE, SL',
    '1564': 'Goldman Sachs Bank Europe SE, Sucursal en España',
    '1497': 'Haitong Bank, S.A., Sucursal en España',
    '1504': 'Honda Bank GmbH, Sucursal en España',
    '0162': 'HSBC Continental Europe, Sucursal en España',
    '2085': 'Ibercaja Banco, S.A.',
    '4832': 'IBERCAJA SERVICIOS DE FINANCIACIÓN, ESTABLECIMIENTO FINANCIERO DE CRÉDITO, S.A.',
    '1514': 'IC FINANCIAL SERVICES, SUCURSAL EN ESPAÑA',
    '1538': 'Industrial and Commercial Bank of China (Europe) S.A., Sucursal en España',
    '1465': 'Ing Bank, N.V. Sucursal en España',
    '1000': 'Instituto de Crédito Oficial',
    '1494': 'Intesa Sanpaolo S.p.A., Sucursal en España',
    '1567': 'J.P. MORGAN SE, SUCURSAL EN ESPAÑA',
    '1482': 'John Deere Bank, S.A., Sucursal en España',
    '0151': 'JPMorgan Chase Bank National Association, Sucursal en España',
    'FI5824': 'KUTXABANK MONETARIO AHORRO, FI',
    '2095': 'Kutxabank, S.A.',
    '1547': 'Lombard Odier (Europe), S.A., Sucursal en España',
    '8342': 'Luzaro E.F.C. S.A',
    '6725': 'MANGOPAY SA SUCURSAL EN ESPAÑA',
    '1520': 'Mediobanca, Sucursal en España',
    '4799': 'Mercedes-Benz Financial Services España E.F.C., S.A.',
    '1552': 'Mirabaud & Cie (Europe) S.A., Sucursal en España',
    '0244': 'MIRALTA FINANCE BANK, S.A.',
    '1559': 'Mizuho Bank Europe N.V., Sucursal en España',
    '6723': 'MODULR FINANCE B.V., SUCURSAL EN ESPAÑA',
    '6720': 'Moneycorp Technologies Limited, S.E',
    '0160': 'MUFG Bank (Europe) N.V., Sucursal en España',
    'FI4082': 'Mutuafondo Dinero, FI',
    '1544': 'MYINVESTOR BANCO, S.A.',
    '1563': 'N26 BANK AG, SUCURSAL EN ESPAÑA',
    '1479': 'Natixis, S.A., Sucursal en España',
    '0133': 'Nuevo Micro Bank, S.A.',
    '8235': 'OCCIDENT HIPOTECARIA, EFC S.A',
    '1577': 'Oddo BHF SCA, Sucursal en España',
    '8814': 'Oney Servicios Financieros, E.F.C., S.A.',
    '0073': 'Open Bank, S.A.',
    '1568': 'Orange Bank, S.A., Sucursal en España',
    '6727': 'OUTPAYCE SA',
    '6722': 'PAGONXT EMONEY EDE, S.L.',
    '6728': 'PAYMENTSENSE IRELAND LIMITED SUCURSAL EN ESPAÑA',
    '6707': 'Pecunia Cards E.D.E. S.L',
    '6713': 'PFS Card Services Ireland Limited, S.E',
    '1508': 'RCI Banque, S.A., Sucursal en España',
    '0083': 'Renta 4 Banco, S.A.',
    '1583': 'REVOLUT BANK UAB SUCURSAL EN ESPAÑA',
    '3138': 'Ruralnostra, Sociedad Cooperativa de Crédito Valenciana',
    '0242': 'Sabadell Consumer Finance, S.A.',
    '0224': 'Santander Consumer Finance, S.A.',
    '0227': 'Unoe Bank, S.A.',
    '8906': 'Santander Factoring y Confirming, S.A., E.F.C.',
    '0030': 'Banco Español De Credito, S.A.',
    '0036': 'Santander Investment, S.A.',
    '4797': 'Santander Lease, S.A. EFC',
    '8845': 'SANTANDER MAPFRE HIPOTECA INVERSA, E.F.C., SA',
    '8813': 'Scania Finance Hispania, E.F.C., S.A.',
    '6705': 'Sefide, E.D.E. S.L.',
    '8795': 'Servicios Financieros Carrefour, E.F.C., S.A.',
    '8833': 'SG Equipment Finance Iberia, E.F.C., S.A.',
    '1490': 'Singular Bank, S.A.',
    '1551': 'SMBC Bank EU AG, Sucursal en España',
    '8816': 'Sociedad Conjunta para la Emisión y Gestión de Medios de Pago, E.F.C., S.A',
    '0108': 'Société Genérale, Sucursal en España',
    '1578': 'SOLARIS SE, SUCURSAL EN ESPAÑA',
    '0100': 'Banco De Vitoria, S.A.',
    '8838': 'STELLANTIS FINANCIAL SERVICES ESPAÑA, E.F.C.,',
    '6724': 'SWAN IO SUCURSAL EN ESPAÑA',
    '8836': 'Telefonica Consumer Finance, EFC, S.A.',
    '1573': 'The Bank of New York Mellon SA/SV, S.E',
    '1570': 'The Governor and Company of the Bank of Ireland, Sucursal en España',
    '1487': 'Toyota Kreditbank GmbH, Sucursal en España',
    '4784': 'Transolver Finance, E.F.C., S.A.',
    '6721': 'TREEZOR S.A.S., SUCURSAL EN ESPAÑA',
    '1491': 'Triodos Bank, N.V., S.E.',
    '1460': 'UBS AG, SUCURSAL EN ESPAÑA',
    '0226': 'UBS Europe SE, Sucursal en España',
    '2103': 'Unicaja Banco, S.A.',
    '1557': 'Unicredit, S.p.A., Sucursal en España',
    '8596': 'Unión Cto. Fin. Mob. e Inm., Credifimo, E.F.C., S.A.',
    '8512': 'Unión de Créditos Inmobiliarios, S.A., E.F.C.',
    '8769': 'Unión Financiera Asturiana, S.A., E.F.C.',
    '6719': 'Unnax Regulatory Services, EDE, SL',
    '6709': 'Up Aganea EDE, SA',
    '8806': 'VFS Financial Services Spain E.F.C., S.A.',
    '1480': 'Volkswagen Bank GmbH, Sucursal en España',
    '1575': 'Western Union International Bank GMBH, Sucursal en España',
    '0229': 'Wizink Bank, S.A.',
    '8840': 'Xfera Consumer Finance, E.F.C. S.A.',
    '1560': 'Younited, Sucursal en España',
    '2054': 'Caja De Ahorros Y Monte De Piedad De Navarra',


        // Añade más códigos de bancos aquí
  };

  changePassword() {
    this.errorMessage = ''
    if (this.new_password === this.repeat_password){
      this.apiService.changePassword(this.password, this.new_password).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          if (res) {
            switch (res.code) {
              case 210:
                this.password=''
                this.new_password=''
                this.repeat_password=''
                this.openPopup();
                break;
              case 512:
                this.errorMessage = 'La contraseña anterior es incorrecta'
                break;
              case 513:
                this.errorMessage = 'Las contraseña nueva es demasiado corta'
                break;
              case 511:
                this.errorMessage = 'Error al cambiar la contraseña'
                break;
              case 510:
                this.errorMessage = 'Error al cambiar la contraseña'
                break;
            }
          } else {
            console.warn('Empty response received');
          }
        },
        error: (error) => {
          console.error('Error fetching derechos: ', error);
        },
      });
    } else{
      this.errorMessage = 'Las contraseñas no coinciden'
    }
  }
}



