<!-- Page Banner -->
<div
    class="page-banner-area ptb-100 four"
    style="background-image: url(../../../../assets/images/solicitudes.jpg);"
>
    <div class="container">
        <div class="page-banner-content">
            <h2>Solicitudes de Prestación</h2>
        </div>
    </div>
</div>


<div class="service-details-area pt-100 pb-100">
  <div class="container">
    <div>
      @if(order=='jubilacion'){
        <div class="info-desc" id="jubilacion">
          <h1>Prestacion por Jubilación</h1>
          <div class="content">
            <h5>¿Cómo puedo cobrar la prestación de jubilación?</h5>
            <p>La prestación por jubilación se podrá percibir de las siguientes formas:.</p>
            <ul class="list">
              <li><p><b>En forma de capital:</b> En el caso de que opte por esta modalidad, tendrá derecho a percibir en un único pago
                el importe de los derechos económicos que ostente al solicitar la prestación. El importe
                para integrar en la base imponible general del Impuesto será el 60% de la prestación
                percibida. La cuantía de los rendimientos sobre los que se aplica el porcentaje de integración del 60% no podrá superar el importe de 300.000 €. El exceso sobre el citado
                importe se integrará al 100%.</p></li>
              <li><p><b>Renta vitalicia:</b> A través de una pensión mensual vitalicia, según el cálculo actuarial que se realice a la
                fecha del hecho causante. A su fallecimiento, los beneficiarios designados a tal efecto,
                percibirán un capital de fallecimiento cuyo importe será el que resulte de deducir de los
                derechos económicos a la fecha de jubilación, las cantidades que hubiese percibido por
                razón de la renta vitalicia, siendo el capital mínimo a percibir de 8.500 €.</p></li>
              <li><p><b>Renta temporal:</b> Se corresponderá con el importe de los derechos económicos de los cuales la persona
                asociada fuera titular a la fecha de la solicitud.</p></li>
            </ul>
          </div>
        </div>
        <aside class="widget-area">
          <div class="widget widget_download_file">
              <h1 class="widget-title">Documentación</h1>
                <ul class="download-file-list">
                    <li>
                        <a href="../../../../assets/files/Solicitud Prestacion de Jubilacion.pdf" target="_blank">
                            <img src="../../../../assets/images/pdf.png" alt="image">
                            <div class="title">
                                <h4>Solicitud de prestación por jubilación </h4>
                                <span>PDF | 201 KB</span>
                            </div>
                        </a>
                    </li>
                </ul>
          </div>
        </aside>
      }
      @if(order=='fallecimiento'){
        <div class="info-desc" id="fallecimiento">
          <h1>Prestacion por Fallecimiento</h1>
          <div class="content">
            <h5>¿Cómo se determina quiénes son los beneficiarios de esta prestación?</h5>
            <p>Los beneficiarios son los establecidos por el socio a través del documento “Designación de Beneficiarios”.</p>
            <aside class="widget-area-mitad">
              <div class="widget widget_download_file">
                <ul class="download-file-list">
                    <li>
                        <a href="../../../../assets/files/Designacion de Beneficiarios- Solicitud pdf  editable.pdf" target="_blank">
                            <img src="../../../../assets/images/pdf.png" alt="image">
                            <div class="title">
                                <h4>Designación de beneficiarios </h4>
                            </div>
                        </a>
                    </li>
                </ul>
              </div>
            </aside>
            <p>Desde el Montepío recomendamos a todos nuestros socios que tengan rellenado dicho documento dado que facilita y agiliza el pago de la prestación.</p>
            <h5>¿Y si no existe documento de beneficiarios?</h5>
            <p>En ausencia de documento, se seguirá el siguiente orden de prelación:</p>
            <ul class="list">
              <li><p>Cónyuge o pareja de hecho registrada.</p></li>
              <li><p>Hijos/as.</p></li>
              <li><p>Hermanos/as.</p></li>
              <li><p>Herederos legales..</p></li>
            </ul>
            <h5>¿Cuál es el plazo para solicitar la prestación?</h5>
            <p>Los beneficiarios de la prestación por el fallecimiento del asociado del Montepío pueden solicitar la prestación cuando lo deseen. Eso sí, sólo se inicia el cobro una vez haya sido tramitada la solicitud de la prestación. Mientras tanto seguiremos gestionando los ahorros.</p>
            <h5>¿Cómo se cobra la prestación de fallecimiento?</h5>
            <p>La indemnización por fallecimiento se devenga, en un único pago, por el importe del “Módulo indemnizatorio” en función del importe fijado anualmente por la Asamblea Anual Ordinaria tanto para fallecimiento por muerte natural como por accidente.</p>
            <p>En el caso de que en el momento del fallecimiento el asociado estuviera percibiendo la prestación de jubilación en su modalidad de renta vitalicia, el importe será la cantidad que resulte de deducir del “módulo indemnizatorio” las cantidades percibidas por dicho concepto, con un <b>mínimo fijado anualmente por la Junta de Gobierno.</b></p>
            <h5>¿Cómo solicito la prestación por fallecimiento del Montepío?</h5>
            <p>Para ellos debes rellenar la <b>“Solicitud de Prestación”</b>, adjuntando toda la documentación que se indica en la misma.</p>
            <p>Una vez que ya tengas todo preparado, envía la documentación por correo ordinario o correo electrónico (<a href="mail:prestaciones&#64;montepioigualatorio.com">prestaciones&#64;montepioigualatorio.com</a>); o también puedes hacerlo en persona en la oficina del Montepío.</p>
            <p>Si tuvieras cualquier duda a la hora de hacer la solicitud, contacta con nosotros y te ayudaremos.</p>
          </div>
        </div>
        <aside class="widget-area">
          <div class="widget widget_download_file">
              <h1 class="widget-title">Documentación</h1>
                <ul class="download-file-list">
                    <li>
                        <a href="../../../../assets/files/Solicitud de prestación por fallecimiento.pdf" target="_blank">
                            <img src="../../../../assets/images/pdf.png" alt="image">
                            <div class="title">
                                <h4>Solicitud de prestación por Fallecimiento </h4>
                                <span>PDF | 147 KB</span>
                            </div>
                        </a>
                    </li>
                </ul>
          </div>
        </aside>
      }
      @if(order=='invalidez'){
        <div class="info-desc" id="invalidez" >
          <h1>Prestacion por Invalidez</h1>
          <div class="content">
            <h5>¿Qué se considera invalidez para el acceso a esta prestación?</h5>
            <p>A la incapacidad permanente por cualquier causa en el grado de absoluta o gran invalidez, declarada y reconocida por los organismos competentes de la Seguridad Social (o los de las correspondientes entidades sustitutorias) que le inhabilite con carácter irreversible para el ejercicio de cualquier actividad profesional o laboral.</p>
            <h5>¿Cuál es el plazo para solicitar la prestación de invalidez?</h5>
            <p>Una vez sea declarada la incapacidad por parte de la Seguridad Social o sentencia firme, puedes solicitar la prestación cuando lo desees. Solo se empieza a cobrar una vez haya sido tramitada la solicitud de la prestación. Mientras tanto podrás seguir beneficiándote de las ventajas de nuestra gestión y tus ahorros continuarán creciendo.</p>
            <h5>¿Cómo se cobra la prestación de invalidez?</h5>
            <p>La prestación de invalidez da derecho a una pensión mensual desde que se reconozca la invalidez y hasta cumplir la edad de jubilación ordinaria. El importe de esta pensión está determinado por la Asamblea cada año.</p>
            <h5>¿Cómo solicito la prestación por invalidez del Montepío?</h5>
            <p>Para ellos debes rellenar la <b>“Solicitud de Prestación”</b>, adjuntando toda la documentación que se indica en la misma.</p>
            <p>Una vez que ya tengas todo preparado, envía la documentación por correo ordinario o correo electrónico (<a href="mail:prestaciones&#64;montepioigualatorio.com">prestaciones&#64;montepioigualatorio.com</a>); o también puedes hacerlo en persona en la oficina del Montepío.</p>
            <p>Si tuvieras cualquier duda a la hora de hacer la solicitud, contacta con nosotros y te ayudaremos.</p>
          </div>
        </div>
        <aside class="widget-area">
          <div class="widget widget_download_file">
              <h1 class="widget-title">Documentación</h1>
                <ul class="download-file-list">
                    <li>
                      <a href="../../../../assets/files/Solicitud de prestación por invalidez.pdf" target="_blank">
                        <img src="../../../../assets/images/pdf.png" alt="image">
                            <div class="title">
                                <h4>Solicitud de prestación por Invalidez </h4>
                                <span>PDF | 85 KB</span>
                            </div>
                        </a>
                    </li>
                </ul>
          </div>
        </aside>
      }
      @if(order=='orfandad'){
        <div class="info-desc" id="orfandad" >
          <h1>Prestacion por Orfandad</h1>
          <div class="content">
            <h5>¿Quiénes son los beneficiarios de esta prestación?</h5>
            <p>Los beneficiarios de esta prestación son los hijos de la persona asociada que queden huérfanos y sean menores de 23 años. Asimismo, se reconoce también como beneficiaros a aquellos hijos que al tiempo de la orfandad se encontraban en situación de discapacidad física o intelectual, quienes recibirán dicha prestación de forma vitalicia. </p>
            <h5>¿Cuál es el plazo para solicitar la prestación de orfandad?</h5>
            <p>Los beneficiarios de la prestación de orfandad por el fallecimiento del asociado del Montepío pueden solicitar la prestación cuando lo deseen. Eso sí, sólo se inicia el cobro una vez haya sido tramitada la solicitud de la prestación. Mientras tanto seguiremos gestionando los ahorros.</p>
            <h5>¿Cómo se cobra la prestación de orfandad?</h5>
            <p>La prestación de orfandad da derecho a una pensión mensual hasta que el beneficiario cumpla los 23 años. En el caso de que el beneficiario se encontrara en el momento de la orfandad en situación de discapacidad física o intelectual, esta prestación tendrá el carácter vitalicio.</p>
            <p>El importe de esta pensión está determinado por la Asamblea cada anualidad.</p>
            <h5>¿Cómo solicito la prestación por orfandad del Montepío?</h5>
            <p>Para ellos debes rellenar la <b>“Solicitud de Prestación”</b>, adjuntando toda la documentación que se indica en la misma.</p>
            <p>Una vez que ya tengas todo preparado, envía la documentación por correo ordinario o correo electrónico (<a href="mail:prestaciones&#64;montepioigualatorio.com">prestaciones&#64;montepioigualatorio.com</a>); o también puedes hacerlo en persona en la oficina del Montepío.</p>
            <p>Si tuvieras cualquier duda a la hora de hacer la solicitud, contacta con nosotros y te ayudaremos.</p>
          </div>
        </div>
        <aside class="widget-area">
          <div class="widget widget_download_file">
              <h1 class="widget-title">Documentación</h1>
                <ul class="download-file-list">
                    <li>
                      <a href="../../../../assets/files/Solicitud de prestación por orfandad.pdf" target="_blank">
                        <img src="../../../../assets/images/pdf.png" alt="image">
                            <div class="title">
                                <h4>Solicitud de prestación por Orfandad </h4>
                                <span>PDF | 204 KB</span>
                            </div>
                        </a>
                    </li>
                </ul>
          </div>
        </aside>
      }
      @if(order=='incapacidad-temporal'){
        <div class="info-desc" id="incapacidad-temporal" >
          <h1>Prestacion por Incapacidad temporal</h1>
          <div class="content">
            <h5>¿Quién puede ser beneficiario de esta prestación?</h5>
            <p>Los profesionales médicos que estén incapacitados temporalmente debido a todo estado patológico que, como consecuencia de enfermedad o accidente, les impida la actividad profesional por más de quince días.</p>
            <p>Además, para causar el derecho a las prestaciones, la persona asociada deberá estar en alta y al corriente del pago de las cuotas.</p>
            <h5>¿Cuál es el plazo para solicitar la prestación de incapacidad temporal?</h5>
            <p>Los beneficiarios de la prestación de incapacidad temporal pueden solicitar la prestación cuando lo deseen. Eso sí, sólo se inicia el cobro una vez haya sido tramitada la solicitud de la prestación. Mientras tanto seguiremos gestionando los ahorros.</p>
            <h5>¿Cómo se cobra la prestación de incapacidad temporal?</h5>
            <p>La incapacidad temporal otorga el derecho a percibir una prestación diaria hasta un límite de 12 meses. El importe de esta prestación está determinado por la Asamblea.</p>
            <h5>¿Cómo solicito la prestación por incapacidad temporal del Montepío?</h5>
            <p>Para ellos debes rellenar la <b>“Solicitud de Prestación”</b>, adjuntando toda la documentación que se indica en la misma.</p>
            <p>Una vez que ya tengas todo preparado, envía la documentación por correo ordinario o correo electrónico (<a href="mail:prestaciones&#64;montepioigualatorio.com">prestaciones&#64;montepioigualatorio.com</a>); o también puedes hacerlo en persona en la oficina del Montepío.</p>
            <p>Si tuvieras cualquier duda a la hora de hacer la solicitud, contacta con nosotros y te ayudaremos.</p>
          </div>
        </div>
        <aside class="widget-area">
          <div class="widget widget_download_file">
              <h1 class="widget-title">Documentación</h1>
                <ul class="download-file-list">
                    <li>
                      <a href="../../../../assets/files/Solicitud de prestación por incapacidad temporal.pdf" target="_blank">
                        <img src="../../../../assets/images/pdf.png" alt="image">
                            <div class="title">
                                <h4>Solicitud de prestación por Incapacidad Temporal </h4>
                                <span>PDF | 85 KB</span>
                            </div>
                        </a>
                    </li>
                </ul>
          </div>
        </aside>
      }
    </div>

  </div>
</div>

