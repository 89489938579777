import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavbarComponent } from './shared/components/header/navbar/navbar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { TopHeaderComponent } from './shared/components/header/top-header/top-header.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { MiddleHeaderComponent } from './shared/components/header/middle-header/middle-header.component';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiService } from './core/services/api.service';
import { AuthInterceptorService, authInterceptor } from './core/interceptors/auth.interceptor';
import { CookieService } from './core/services/cookie.service';
import { FormsModule } from '@angular/forms';
import { LoginPageComponent } from './pages/auth/login-page/login-page.component';
import { UserDataComponent } from './pages/user-data/user-data/user-data.component';
import { InfoComponent } from './pages/info-pages/information/info.component';
import { ContactComponent } from './pages/contact/contact.component';
import { MyContributionsComponent } from './pages/user-data/contributions/my-contributions/my-contributions.component';
import { ConsolidatedRightsComponent } from './pages/user-data/contributions/consolidated-rights/consolidated-rights.component';
import { HashLocationStrategy, LocationStrategy, registerLocaleData  } from '@angular/common';

import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { CertificatesComponent } from './pages/info-pages/certificates/certificates.component';
import { PrestacionComponent } from './pages/info-pages/prestaciones/prestacion.component';
import { RecoverPasswordComponent } from './pages/auth/recover-password/recover-password.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { SolicitudesPrestacionDetalleComponent } from './pages/info-pages/solicitudes-prestacion-detalle/solicitudes-prestacion-detalle.component';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NewPasswordComponent } from './pages/auth/new-password/new-password.component';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import localeEs from '@angular/common/locales/es';

import { NativeDateAdapter, DateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';


@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  // Personalizar el parse para garantizar que el formato sea dd/MM/yyyy
  override parse(value: any): Date | null {
    if (typeof value === 'string') {
      const parts = value.split('/');
      if (parts.length === 3) {
        const [day, month, year] = parts;
        return new Date(Number(year), Number(month) - 1, Number(day)); // Meses empiezan en 0
      }
    }
    return super.parse(value);  // Llamada a la implementación base
  }

  // Personalizar la salida para que siempre se muestre en formato dd/MM/yyyy
  override format(date: Date, displayFormat: any): string {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }
}


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'dd/MM/yyyy',  // Formato de entrada (día/mes/año)
  },
  display: {
    dateInput: 'dd/MM/yyyy',  // Formato de visualización (día/mes/año)
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'dd/MM/yyyy',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      var def:any = params.interpolateParams;
      return def.Default || params.key;
    }
    return params.key;
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeEs);


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    TopHeaderComponent,
    HeaderComponent,
    MiddleHeaderComponent,
    LoginPageComponent,
    UserDataComponent,
    InfoComponent,
    ContactComponent,
    MyContributionsComponent,
    ConsolidatedRightsComponent,
    CertificatesComponent,
    NotFoundComponent,
    PrestacionComponent,
    RecoverPasswordComponent,
    SolicitudesPrestacionDetalleComponent,
    NewPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatPaginator,
    MatDialogModule,
    MatTabsModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    MatSort,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    TranslateModule.forRoot({
      useDefaultLang: false,
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MissingTranslationHelper},
    }),
    NgxScrollTopModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [
    ApiService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    {provide : LocationStrategy , useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: DateAdapter, useClass: CustomDateAdapter },  // Usar el adaptador personalizado



  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
