import { AfterViewInit, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonService } from '../../core/services/common.service';
import { Contact } from '../../shared/models/contact.model';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../core/services/api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Reclamacion } from '../../shared/models/reclamacion.model';
import { Documento } from '../../shared/models/documento.model';

interface FileUpload {
  file: File;
  isUploaded: boolean;
}


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent implements AfterViewInit, OnInit{

  noTipo: Boolean = false
  noReclamacion: Boolean = false
  dniInvalido: Boolean = false

  referencia: string = ''

  loading = false

  contacto: Contact; // Instancia de la clase Contact
  reclamacion: Reclamacion;
  contactoForm: FormGroup;
  reclamacionForm: FormGroup;

  name: string = '';
  email: string = '';
  telefono: string = '';
  asunto: string = '';
  mensaje: string = '';

  userName: string = ''

  errorMessage: string = '';
  errorMessageReclamacion: string = '';
  errorMessageArchivos: string = '';


  destroyRef = inject(DestroyRef)

  name_reclamacion: string = '';
  dni: string  = '';
  direccion: string = ''
  email_reclamacion: string = '';
  telefono_reclamacion: string = '';
  socio: number = 0
  fecha: string  = ''
  descripcion: string = ''
  tipo: string = 'Servicios'
  tipoOtros: string = ''
  reclamacion_anterior: string = 'No'
  number: string = ''
  archivo1_nombre: string = ''
  archivo1_data: string = ''
  archivo2_nombre: string = ''
  archivo2_data: string = ''
  archivo3_nombre: string = ''
  archivo3_data: string = ''
  solicitud: string = ''


  constructor(
    public commonService: CommonService,
    public apiService: ApiService,
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    this.contacto = new Contact()
    this.contactoForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      phone_number: ['', [Validators.minLength(9), Validators.pattern(/^[0-9]*$/)]],
      msg_subject: ['', Validators.required],
      message: ['', Validators.required]
    })
    this.reclamacion  = new Reclamacion()
    this.reclamacionForm = this.fb.group({
      name: ['', Validators.required],
      dni: ['', Validators.required],
      adress: [''],
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      phone_number: ['', [Validators.minLength(9), Validators.pattern(/^[0-9]*$/)]],
      socio: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      descripcion: ['', Validators.required],
      fecha: ['', Validators.required],
      solicitud: ['', Validators.required],
      tipo: [''],
      tipoOtros: [''],
      number: [''],
      reclamacion_anterior: [''],
    });
  }

  ngOnInit(): void {
    this.userName = JSON.parse(localStorage.getItem("currentUser") || '').name
    this.userName = this.userName.toLowerCase().replace(/(?:^|\s)\S/g, match => match.toUpperCase())
    this.commonService.nombre = this.userName
    this.name_reclamacion = this.userName
    this.getData()
  }

  getData(){
    this.apiService.getDataRelamación().pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          if (res) {
            this.dni = res.dni
            this.direccion = res.address
            this.telefono_reclamacion = res.phone
            this.email_reclamacion =  res.email
            this.socio = res.number
          } else {

          }
        },
        error: (error) => {

        },
      });
  }


  sendFormData(formData: Contact) {
    console.log(JSON.stringify(formData))
    // this.http.post('https://tu-api.com/contacto', formData)
    //   .subscribe(
    //     (data: any) => {
    //       console.log('Respuesta de la API:', data);
    //       alert('Mensaje enviado correctamente');
    //       this.contactoForm.reset(); // Reiniciar el formulario después del envío
    //     },
    //     (error: any) => {
    //       console.error('Error al enviar el formulario:', error);
    //       alert('Hubo un problema al enviar el mensaje, por favor inténtalo de nuevo');
    //     }
    //   );
  }

files: { name: string; base64Data: string }[] = [];
fileUploads: FileUpload[] = [];
dragOver = false;
maxFiles = 3; // Número máximo de archivos permitido


onFileSelected(event: Event): void {
  const input = event.target as HTMLInputElement;
  if (input.files) {
    const remainingSlots = this.maxFiles - this.files.length;
    if (input.files.length > remainingSlots) {
      this.openPopup3()
      return; // Salir de la función si se excede el límite
    } else {
      Array.from(input.files).forEach((file: File) => {
        if(!this.isFileAlreadyUploaded(file)){
          if (this.files.length < this.maxFiles) {
            this.readFile(file);
          } else{
            this.openPopup3()
          }
        }

      });    }

  }
}

onDragOver(event: DragEvent): void {
  event.preventDefault();
  event.stopPropagation();
  this.dragOver = true;
}

onDragLeave(event: DragEvent): void {
  event.preventDefault();
  event.stopPropagation();
  this.dragOver = false;
}

onDrop(event: DragEvent): void {
  event.preventDefault();
  event.stopPropagation();
  this.dragOver = false;
  if (event.dataTransfer?.files) {
    const remainingSlots = this.maxFiles - this.files.length;
    if (event.dataTransfer.files.length > remainingSlots) {
      this.openPopup3()
    } else {
      Array.from(event.dataTransfer.files).forEach((file: File) => {
        if(!this.isFileAlreadyUploaded(file)){
          if (this.files.length < this.maxFiles) {
            this.readFile(file);
          } else{
            this.openPopup3()
          }
        }
      });    }

  }
}

isFileAlreadyUploaded(newFile: File): boolean {
  return this.fileUploads.some(item => item.file.name === newFile.name);
}

removeFile(index: number): void {
  this.files.splice(index, 1);
  this.fileUploads.splice(index, 1);
}

// Leer el archivo y convertirlo a Base64
private readFile(file: File): void {
  const reader = new FileReader();
  reader.onload = () => {
    const base64Data = (reader.result as string).split(',')[1];
    this.files.push({ name: file.name, base64Data });
    this.fileUploads.push({ file, isUploaded: false });
  };
  reader.readAsDataURL(file);
}

// Simular la subida del archivo (ejemplo)
uploadFile(index: number): void {
  // Aquí se simularía la subida del archivo al servidor
  setTimeout(() => {
    this.fileUploads[index].isUploaded = true;
  }, 1000);
}


  ngAfterViewInit() {
    const delay = 0; // <= eso es ser rapido
    setTimeout(() => {
      this.commonService.espacioComponente()
     }, delay);

  }

  isOpen = false;
  openPopup(): void {
      this.isOpen = true;
  }
  closePopup(): void {
      this.isOpen = false;
  }

  isOpen2 = false;
  openPopup2(): void {
      this.isOpen2 = true;
  }
  closePopup2(): void {
      this.isOpen2 = false;
  }

  isOpen3 = false;
  openPopup3(): void {
      this.isOpen3 = true;
  }
  closePopup3(): void {
      this.isOpen3 = false;
  }

  // optionalMinLength(minLength: number): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     const value = control.value;
  //     if (value && value.length < minLength) {
  //       return { 'optionalMinLength': { value: value } };
  //     }
  //     return null;
  //   };

  // }


  enviarMensaje() {
    this.contacto.name=this.name
    this.contacto.email=this.email
    this.contacto.phone=this.telefono
    this.contacto.subject=this.asunto
    this.contacto.message=this.mensaje

    this.errorMessage = ''


    this.apiService.contactUs(this.contacto).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          if (res) {
            if(res.code==210){
              this.openPopup()
              this.contactoForm.reset(); // Reiniciar el formulario después del envío

            } else{
              this.errorMessage = 'Error al enviar el mensaje'
            }
          } else {
            this.errorMessage = 'Error al enviar el mensaje'

          }
        },
        error: (error) => {
          this.errorMessage = 'Error al enviar el mensaje'

        },
      });
  }

  enviarReclamacion() {
  this.loading=true

  this.reclamacion.name=this.name_reclamacion
  this.reclamacion.dni = this.dni
  this.reclamacion.domicilio=this.direccion
  this.reclamacion.email=this.email_reclamacion
  this.reclamacion.phone=this.telefono_reclamacion
  this.reclamacion.numeroSocio=this.socio
  this.reclamacion.fechaQueja=this.fecha
  this.reclamacion.descripcionQueja = this.descripcion
  this.reclamacion.tipoQueja = this.tipo
  this.reclamacion.reclamacionAnterior = this.reclamacion_anterior
  this.reclamacion.archivo1_nombre = this.files[0]?.name || ''
  this.reclamacion.archivo1_data = this.files[0]?.base64Data || ''
  this.reclamacion.archivo2_nombre = this.files[1]?.name || ''
  this.reclamacion.archivo2_data = this.files[1]?.base64Data || ''
  this.reclamacion.archivo3_nombre = this.files[2]?.name || ''
  this.reclamacion.archivo3_data = this.files[2]?.base64Data || ''
  this.reclamacion.solicitudReclamante = this.solicitud

  if(this.tipo == 'otros') {
    if(this.tipoOtros!=''){
      this.reclamacion.tipoQueja = this.tipoOtros
      this.noTipo=false
    } else{
      this.noTipo=true
    }
  }

  if(this.reclamacion_anterior == 'Si') {
    if(this.number!=''){
      this.reclamacion.reclamacionAnterior = this.number
      this.noReclamacion=false
    } else{
      this.noReclamacion=true
    }
  }

  if(this.commonService.isValidSpanishDNI(this.dni)){
    this.dniInvalido=false
  } else{
    this.dniInvalido=true
  }

  this.errorMessageReclamacion = ''

  if(!this.noReclamacion && !this.noTipo && !this.dniInvalido){
    this.apiService.reclamacion(this.reclamacion).pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: (res) => {
        if (res) {
          if(res.code==210){
            this.referencia = res.texto
            this.openPopup2()
            this.files = []
            this.reclamacionForm.patchValue({
              tipo: 'Servicios', // Resetea 'campo1'
              tipoOtros: '',
              reclamacion_anterior: 'No',
              number: '',
              solicitud: '',
              descripcion: '',
              fecha: ''

              // Añade aquí los campos que quieras resetear
          });
          this.loading=false

          } else{
            this.errorMessageReclamacion = 'Error al enviar la reclamacion'
            this.loading=false

          }
        } else {
          this.errorMessageReclamacion = 'Error al enviar la reclamacion'
          this.loading=false


        }
      },
      error: (error) => {
        this.errorMessageReclamacion = 'Error al enviar la reclamacion'
        this.loading=false

      },
    });
  } else{
    this.errorMessageReclamacion = 'Hay errores en el formulario'
    this.loading=false

  }
  }


}



interface FileUpload {
  file: File;
  isUploaded: boolean;
  base64?: string;  // base64 es una propiedad opcional
}
