<!-- Page Banner -->
<div
    class="page-banner-area ptb-100 four"
    style="background-image: url(../../../../assets/images/contact.jpg);"
>
    <div class="container">
        <div class="page-banner-content">
            <h2>Contacto</h2>
        </div>
    </div>
</div>

<!-- Contact Information -->
<div class="covid-information-area pt-100">
  <div class="container">
      <div class="row justify-content-center">
          <div class="col-lg-3 col-sm-6">
              <div class="covid-info-card">
                  <div class="icon">
                      <i class='bx bx-map'></i>
                  </div>
                  <h3>Dirección:</h3>
                  <p>Gran Vía Diego López de Haro, 81 – 5º dpto 4
                    48011 Bilbao - Bizkaia</p>
              </div>
          </div>
          <div class="col-lg-3 col-sm-6">
              <div class="covid-info-card">
                  <div class="icon">
                      <i class='bx bx-send'></i>
                  </div>
                  <h3>Contacto:</h3>
                  <p><a href="mail:contacto&#64;montepioigualatorio.com">contacto&#64;montepioigualatorio.com</a></p>
              </div>
          </div>
          <div class="col-lg-3 col-sm-6">
              <div class="covid-info-card">
                  <div class="icon">
                      <i class='bx bxs-phone-call'></i>
                  </div>
                  <h3>Teléfono:</h3>
                  <span>
                  </span>
                  <p><a href="tel:946345157">94 634 51 57</a></p>
                  <p><a href="tel:644752256">644 75 22 56</a></p>
              </div>
          </div>
          <div class="col-lg-3 col-sm-6">
              <div class="covid-info-card">
                  <div class="icon">
                      <i class='bx bx-calendar'></i>
                  </div>
                  <h3>Horario:</h3>
                  <p>Lunes a viernes: 9:30 - 14:00</p>
                  <p>Para una atención más personalizada, solicitar cita en contacto&#64;montepioigualatorio.com</p>
              </div>
          </div>
      </div>
  </div>
</div>



<!-- Contact Us -->
<div class="covid-contact-area pb-100">
  <div class="pestañas container">
    <mat-tab-group>
      <mat-tab label="Contacto">
        <div class="section-title with-covid-color">
          <h2>Contacta con nosotros</h2>
        </div>
        <div class="covid-contact-form">
          <form [formGroup]="contactoForm">
            <div class="row">
              <div class="col-lg-6 col-sm-6">
                <div class="form-group">
                  <label>Nombre *</label>
                  <input type="text" [(ngModel)]="name" formControlName="name" class="form-control" placeholder="Nombre">
                  <div *ngIf="contactoForm.get('name')?.errors" class="text-danger">
                    <div *ngIf="contactoForm.get('name')?.hasError('required')">Campo requerido</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="form-group">
                  <label>Correo electrónico *</label>
                  <input type="email" [(ngModel)]="email" formControlName="email" class="form-control" placeholder="Correo electrónico">
                  <div *ngIf="contactoForm.get('email')?.errors" class="text-danger">
                    <div *ngIf="contactoForm.get('email')?.hasError('required')">Campo requerido</div>
                    <div *ngIf="contactoForm.get('email')?.hasError('pattern')">Correo electrónico no válido</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="form-group">
                  <label>Teléfono</label>
                  <input type="text" [(ngModel)]="telefono" formControlName="phone_number" class="form-control" placeholder="Teléfono">
                  <div *ngIf="contactoForm.get('phone_number')?.errors" class="text-danger">
                    <div *ngIf="contactoForm.get('phone_number')?.hasError('minlength')">Número invalido</div>
                    <div *ngIf="contactoForm.get('phone_number')?.hasError('pattern')">Deben ser números</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6">
                <div class="form-group">
                  <label>Asunto *</label>
                  <input type="text" [(ngModel)]="asunto" formControlName="msg_subject" class="form-control" placeholder="Asunto">
                  <div *ngIf="contactoForm.get('msg_subject')?.errors" class="text-danger">
                    <div *ngIf="contactoForm.get('msg_subject')?.hasError('required')">Campo requerido</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-sm-12">
                <div class="form-group">
                  <label>Mensaje *</label>
                  <textarea formControlName="message" [(ngModel)]="mensaje" class="form-control" placeholder="Mensaje"></textarea>
                  <div *ngIf="contactoForm.get('message')?.errors" class="text-danger">
                    <div *ngIf="contactoForm.get('message')?.hasError('required')">Campo requerido</div>
                  </div>
                </div>
              </div>
              <div *ngIf="errorMessage" class="error-message col-lg-8 col-md-6">
                {{ errorMessage }}
              </div>
              <div class="col-lg-12 col-sm-12">
                <button type="submit" class="default-btn" [disabled]="!contactoForm.valid" (click)="enviarMensaje()">
                  Enviar mensaje
                </button>
              </div>
            </div>
          </form>
        </div>


      </mat-tab>
      <mat-tab label="Reclamaciones">
        <div class="section-title with-covid-color">
          <h2>Reclamaciones</h2>
        </div>
        <div class="doc">
          <p>En caso de hacer una reclamación, puede rellenar los datos o bien descargar el siguente documento. En caso de descargar el documento, podrá rellenarlo y enviarlo a la direccion de correo reclamaciones&#64;montepioigualatorio.com</p>
          <aside class="widget-area">
            <div class="widget widget_download_file">
              <ul class="download-file-list">
                <li class="mb-3">
                  <a href="../../../assets/files/Manual de quejas y reclamaciones.pdf" target="_blank">
                      <img src="../../../../assets/images/pdf.png" alt="image">
                      <div class="title">
                          <h4>Manual de quejas y reclamaciones</h4>
                          <span>PDF | 168 KB</span>
                      </div>
                    </a>
                </li>
                <li>
                  <a href="../../../assets/files/Hoja de reclamaciones.pdf" target="_blank">
                      <img src="../../../../assets/images/pdf.png" alt="image">
                      <div class="title">
                          <h4>Hoja de reclamaciones</h4>
                          <span>PDF | 80 KB</span>
                      </div>
                  </a>
                </li>
              </ul>
            </div>
          </aside>
        </div>
        <div class="covid-contact-form">
          <form [formGroup]="reclamacionForm">
              <div class="row">
                  <h5>Datos del reclamante</h5>
                  <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                          <label>Nombre y apellidos *</label>
                          <input type="text" name="name" id="name" formControlName="name" [(ngModel)]="name_reclamacion" class="form-control" required data-error="Please enter your name" placeholder="Nombre y apellidos">
                          <div *ngIf="reclamacionForm.get('name')?.errors" class="text-danger">
                            <div *ngIf="reclamacionForm.get('name')?.hasError('required')">Campo obligatorio</div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>DNI *</label>
                        <input type="text" name="dni" id="dni" formControlName="dni" [(ngModel)]="dni" class="form-control" required data-error="Please enter your name" placeholder="DNI/Pasaporte">
                        <div *ngIf="reclamacionForm.get('dni')?.errors" class="text-danger">
                          <div *ngIf="reclamacionForm.get('dni')?.hasError('required')">Campo obligatorio</div>
                        </div>
                        @if(dniInvalido){
                          <div class="text-danger">
                            <div>DNI inválido</div>
                          </div>
                        }
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>Dirección *</label>
                        <input type="text" name="adress" id="adress" formControlName="adress" [(ngModel)]="direccion" class="form-control" required data-error="Please enter your name" placeholder="Domicilio">
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>Teléfono *</label>
                        <input type="text" name="phone_number" id="phone_number" formControlName="phone_number" [(ngModel)]="telefono_reclamacion" class="form-control" required data-error="Please enter your name" placeholder="Teléfono">
                        <div *ngIf="reclamacionForm.get('phone_number')?.errors" class="text-danger">
                          <div *ngIf="reclamacionForm.get('phone_number')?.hasError('minlength')">Número invalido</div>
                          <div *ngIf="reclamacionForm.get('phone_number')?.hasError('pattern')">Deben ser números</div>
                        </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>Correo electrónico *</label>
                        <input type="text" name="email" id="email" formControlName="email" [(ngModel)]="email_reclamacion" class="form-control" placeholder="Correo electrónico">
                        <div *ngIf="reclamacionForm.get('email')?.errors" class="text-danger">
                          <div *ngIf="reclamacionForm.get('email')?.hasError('required')">Campo obligatorio</div>
                          <div *ngIf="reclamacionForm.get('email')?.hasError('pattern')">Correo electrónico no válido</div>
                        </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>Número de asociado/a *</label>
                        <input type="text" name="socio" id="socio" formControlName="socio" [(ngModel)]="socio" class="form-control" required data-error="Please enter your name" placeholder="Número de asociado">
                        <div *ngIf="reclamacionForm.get('socio')?.errors" class="text-danger">
                          <div *ngIf="reclamacionForm.get('socio')?.hasError('required')">Campo obligatorio</div>
                          <div *ngIf="reclamacionForm.get('socio')?.hasError('pattern')">Deben ser números</div>
                        </div>
                    </div>
                  </div>

                  <h5>Datos de la queja/reclamación</h5>
                  <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                          <label>Fecha del incidente *</label>
                          <input type="date" name="fecha" formControlName="fecha" [(ngModel)]="fecha" id="fecha_incidente" class="form-control" required data-error="Please enter your name" placeholder="Fecha del incidente">
                          <div *ngIf="reclamacionForm.get('fecha')?.errors" class="text-danger">
                            <div *ngIf="reclamacionForm.get('fecha')?.hasError('required')">Campo obligatorio</div>
                          </div>
                        </div>
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <div class="form-group">
                        <label>Descripción de los hechos *</label>
                        <textarea name="descripcion" formControlName="descripcion" [(ngModel)]="descripcion" class="form-control" id="message" cols="30" rows="5" required data-error="Write your message" placeholder="Descripción de los hechos"></textarea>
                        <div *ngIf="reclamacionForm.get('descripcion')?.errors" class="text-danger">
                          <div *ngIf="reclamacionForm.get('descripcion')?.hasError('required')">Campo obligatorio</div>
                        </div>
                      </div>
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <div class="form-group">
                        <label>Tipo de queja *</label>
                        <ul>
                          <li>
                              <label>
                                  <input type="radio" formControlName="tipo" [(ngModel)]="tipo" name="tipo" value="Servicios" checked>
                                  Servicios
                              </label>
                          </li>
                          <li>
                              <label>
                                  <input type="radio" formControlName="tipo" [(ngModel)]="tipo" name="tipo" value="Atención al cliente">
                                  Atención al Cliente
                              </label>
                          </li>
                          <li>
                              <label>
                                  <input type="radio" formControlName="tipo" [(ngModel)]="tipo" name="tipo" value="otros">
                                  Otros (especifique):
                                  <input type="text" formControlName="tipoOtros" [(ngModel)]="tipoOtros" class="form-control otros" name="tipoOtros">
                                  @if(noTipo){
                                    <div class="text-danger">
                                      <div>Indique el tipo de queja</div>
                                    </div>
                                  }

                              </label>
                          </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <div class="form-group">
                        <label>Reclamación anterior *</label>
                        <ul>
                          <li>
                              <label>
                                  <input type="radio" formControlName="reclamacion_anterior" [(ngModel)]="reclamacion_anterior" name="reclamacion_anterior" value="No">
                                  No
                              </label>
                          </li>
                          <li>
                              <label>
                                  <input type="radio" formControlName="reclamacion_anterior" [(ngModel)]="reclamacion_anterior" name="reclamacion_anterior" value="Si">
                                  Sí
                                </label>
                                <label>Si respondió "Sí", insique el nímero de referencia de la reclamación anterior</label>
                                <input type="text" name="number" formControlName="number" [(ngModel)]="number" id="number" class="form-control"  placeholder="Número de referencia">
                                @if(noReclamacion){
                                  <div class="text-danger">
                                    <div>Indique el tipo de queja</div>
                                  </div>
                                }
                          </li>
                      </ul>
                    </div>
                  </div>

                  <div class="container mt-5">
                    <h5>Documentos adjuntos</h5>
                    <div class="row">
                      <div class="col-lg-12 col-sm-12">
                        <div class="form-group file-upload">
                          <label for="adjunto">Adjuntar archivos</label>
                          <label for="adjunto" class="custom-file-upload">
                            Seleccionar archivos (Máximo 3 archivos)
                          </label>
                          <input type="file" name="adjunto[]" id="adjunto" (change)="onFileSelected($event)" multiple>
                        </div>
                        <div
                          class="drop-zone"
                          [class.drag-over]="dragOver"
                          (dragover)="onDragOver($event)"
                          (dragleave)="onDragLeave($event)"
                          (drop)="onDrop($event)"
                        >
                          <p>Arrastra y suelta los archivos aquí o haz clic para seleccionar.</p>
                          <div class="uploaded-files" *ngIf="files.length > 0">
                            <h6>Archivos subidos:</h6>
                            <table class="table table-bordered">
                              <tbody>
                                <tr *ngFor="let file of files; let i = index">
                                  <td>{{ file.name }}</td>
                                  <td class="text-center borrar">
                                    <button type="button" class="btn btn-danger btn-sm" (click)="removeFile(i)">X</button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <h5>Solicitud del reclamante *</h5>
                  <div class="col-lg-12 col-sm-12">
                    <div class="form-group">
                        <label>Solicitud</label>

                        <textarea name="solicitud" formControlName="solicitud" [(ngModel)]="solicitud" class="form-control" id="message" cols="30" rows="5" required data-error="Write your message" placeholder="Describa de manera clara y concreta cuál es su solicitud o lo que espera obtener como resultado de su queja o reclamación."></textarea>
                        <div *ngIf="reclamacionForm.get('solicitud')?.errors" class="text-danger">
                          <div *ngIf="reclamacionForm.get('solicitud')?.hasError('required')">Campo obligatorio</div>
                        </div>
                      </div>
                  </div>

                  <h5>Declaración del reclamante</h5>
                  <div class="col-lg-12 col-sm-12">
                    <div class="form-group">
                        <label>Declaro que todos los datos aquí consignados son ciertos y reflejan fielmente los hechos tal y como ocurrieron. Asimismo, autorizo al Montepio del Igualatorio EPSV a utilizar estos datos para la gestión de mi queja o reclamación.</label>

                    </div>
                  </div>

                  <div *ngIf="errorMessageReclamacion" class="error-message col-lg-8 col-md-6">
                    {{ errorMessageReclamacion }}
                  </div>

                  <div class="col-lg-12 col-sm-12">

                    <button type="submit" class="default-btn"  [disabled]="!reclamacionForm.valid" (click)="enviarReclamacion()" >
                      @if(loading){
                        <div class="popup-inner">
                          <div class="gif-container">
                            <img src="../../../../assets/images/load-8510_256.gif" alt="GIF animado">
                          </div>
                        </div>
                      } @else {
                        Enviar reclamación
                      }
                    </button>
                  </div>
              </div>
          </form>
        </div>

      </mat-tab>
    </mat-tab-group>

  </div>
</div>


<div class="video-popup" *ngIf="isOpen">
  <div class="popup-inner">
    <h5>Mensaje enviado</h5>
    <p>Nos pondremos en contacto con usted lo antes posible.</p>
    <div class="close-button-container">
      <button (click)="closePopup()" class="popup-btn" type="button">Cerrar</button>
    </div>
  </div>
</div>

<div class="video-popup" *ngIf="isOpen2">
  <div class="popup-inner">
    <h5>Reclamación enviada</h5>
    <p>Nos pondremos en contacto con usted lo antes posible.</p>
    <p>Por favor guarde el siguiene numero de referencia de la reclamacion: {{referencia}}</p>
    <div class="close-button-container">
      <button (click)="closePopup2()" class="popup-btn" type="button">Cerrar</button>
    </div>
  </div>
</div>

<div class="video-popup" *ngIf="isOpen3">
  <div class="popup-inner">
    <h5>Error</h5>
    <p>Solo puede haber 3 archivos subidos.</p>
    <div class="close-button-container">
      <button (click)="closePopup3()" class="popup-btn" type="button">Cerrar</button>
    </div>
  </div>
</div>
