
export class Empresa{

    id: number
    nombre: string
    codigo: string


    constructor() {
      this.id = 0
      this.nombre = ''
      this.codigo = ''

    }

   }
