<!-- Page Banner -->
<div
    class="page-banner-area ptb-100 four"
    style="background-image: url(../../../../assets/images/myData.jpg);"
>
    <div class="container">
        <div class="page-banner-content">
            <h2>Mis datos</h2>
        </div>
    </div>
</div>

<div class="container">
  <div class="row preBienvenido">
    <span class="row sub-title">
      <div class="row">
        <div class="bienvenido col-lg-6 col-sm-12">
          <div>
            <i class="flaticon-042-user"></i>
          </div>
          <div>
            <h5>Hola, {{ userName }}</h5>
            <span>Bienvenido a tu área privada</span>
          </div>
        </div>
        <div class="preCalendar col-lg-6 col-sm-12">
          <div class="calendar row">
            <div class="col-3">
              <i class="flaticon-084-calendar"></i>
            </div>
            <div class="col-9">
              <span class="textCalendar">{{ currentDate }}</span>
            </div>
          </div>
        </div>
      </div>
    </span>
  </div>
</div>

@if (loaded) {
  <div class="pestañas container">
    <mat-tab-group>
      <mat-tab label="Datos personales">
        <div class="covid-contact-form-disabled">
          <form>
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>NOMBRE</label>
                        <input type="text" name="nombre" id="nombre" class="form-control" placeholder="{{userName}}" disabled>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>DNI</label>
                        <input type="text" name="dni" id="dni" class="form-control" placeholder="{{ userData.dni }}" disabled>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>GÉNERO</label>
                        <input type="text" name="sexo" id="sexo" class="form-control" placeholder="{{ userData.genero }}" disabled>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                      <label>FECHA DE NACIMIENTO</label>
                      <input type="text" name="fecha_nacimiento" id="fecha_nacimiento" class="form-control" placeholder="{{ userData.fnac}}" disabled>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <div class="form-group">
                      <label>EMAIL</label>
                      <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="{{ userData.email }}"disabled>
                      <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>TELÉFONO 1</label>
                        <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="{{ userData.tel1 }}" disabled>
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                      <label>TELÉFONO 2</label>
                      <input type="text" name="phone_number2" id="phone_number2" required data-error="Please enter your number" class="form-control" placeholder="{{ userData.tel2 }}" disabled>
                      <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>DIRECCIÓN</label>
                        <input type="text" name="direccion" id="direccion" class="form-control" required data-error="Please enter your adress" placeholder="{{ userData.direccion }}" disabled>
                        <div class="help-block with-errors"></div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                      <label>CP</label>
                      <input type="text" name="cp" id="cp" class="form-control" required data-error="Please enter your CP" placeholder="{{ userData.cp }}" disabled>
                      <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                      <label>LOCALIDAD</label>
                      <input type="text" name="localidad" id="localidad" class="form-control" required data-error="Introduce tu localidad" placeholder="{{ userData.poblacion }}" disabled>
                      <div class="help-block with-errors"></div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                      <label>PROVINCIA</label>
                      <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Introduce tu provincia" placeholder="{{ userData.provincia }}" disabled>
                      <div class="help-block with-errors"></div>
                  </div>
                </div>
              </div>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="Datos de asociado/a">
        <div class="covid-contact-form-disabled">
          <form>
              <div class="row">
                  <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                          <label>FECHA DE ALTA</label>
                          <input type="text" name="alta" id="alta" class="form-control" placeholder="{{ userData.alta}}" disabled>
                      </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>TIPO DE PRESTACIÓN</label>
                        @if(userData.tipo){
                          <input type="text" name="prestacion" id="prestacion" class="form-control" placeholder="{{ userData.tipo.nombre }}" disabled>
                        }@else{
                          <input type="text" name="prestacion" id="prestacion" class="form-control" placeholder="" disabled>
                        }
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                          <label>SITUACIÓN</label>
                          @if(userData.situacion){
                            <input type="text" name="situacion" id="situacion" class="form-control" placeholder="{{ userData.situacion.nombre }}" disabled>
                          }@else{
                            <input type="text" name="situacion" id="situacion" class="form-control" placeholder="" disabled>
                          }
                      </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>FECHA DE JUBILACIÓN</label>
                        <input type="text" name="jubilacion" id="jubilacion" class="form-control" placeholder="{{userData.jubilacion}}" disabled>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>DISCAPACITADO</label>
                        <input type="text" name="discapacitado" id="discapacitado" class="form-control" placeholder="{{ userData.discapacidad }}" disabled>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>NÚMERO ASOCIADO</label>
                        <input type="text" name="numero" id="numero" class="form-control" placeholder="{{userData.numero}}" disabled>
                    </div>
                  </div>
                  <div class="col-lg-12 col-sm-12">
                    <div class="form-group">
                        <label>IBAN ({{nombreBanco}})</label>
                        <input type="text" name="grupo" id="grupo" class="form-control" placeholder="{{userData.iban}}" disabled>
                    </div>
                  </div>
              </div>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="Cambiar contraseña">
        <div class="covid-contact-form col-lg-6 col-md-12">
          <form>
              <div class="row">
                  <div class="col-lg-12 col-sm-6">
                      <div class="form-group">
                          <label>Contraseña actual</label>
                          <input type="password" name="contraseña_actual" [(ngModel)]="password" id="contraseña_actual" class="form-control pass" required data-error="Please enter your password" placeholder="Contraseña actual">
                          <div class="help-block with-errors"></div>
                      </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                          <label>Nueva contraseña</label>
                          <input type="password" name="new_password" [(ngModel)]="new_password" id="contraseña_nueva" class="form-control pass" placeholder="Contraseña nueva">
                          <div class="help-block with-errors"></div>
                      </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                          <label>Repetir contraseña</label>
                          <input type="password" name="repeat_password" [(ngModel)]="repeat_password" id="contraseña_nueva2" class="form-control pass" data-error="Las contraseñas deben coincidir" placeholder="Repetir contraseña">
                          <div class="help-block with-errors"></div>
                      </div>
                  </div>
                  <div *ngIf="errorMessage" class="error-message col-lg-8 col-md-6">
                    {{ errorMessage }}
                  </div>
                  <div class="col-lg-12 col-sm-12">
                      <button type="submit" class="default-btn" [disabled]="!password || !new_password || !repeat_password" (click)="changePassword()">
                          Guardar
                      </button>
                  </div>

              </div>
          </form>
        </div>
      </mat-tab>

    </mat-tab-group>
    <p class="msn">En caso de haber algún dato erroneo, por favor, pongase en contacto con nosotros a traves de la página de contacto o directamente envíe un mensaje al correo <a href="mailto:contacto@montepioigualatorio.com">contacto&#64;montepioigualatorio.com</a></p>

  </div>
} @else {
  <div class="gif-container">
    <img src="../../../../assets/images/load-8510_256.gif" alt="GIF animado">
  </div>
}



<div class="video-popup" *ngIf="isOpen">
  <div class="popup-inner">
    <h5>Contraseña actualizada</h5>
    <p>La contraseña ha sido actualizada correctamente.</p>
    <div class="close-button-container">
      <button (click)="closePopup()" class="popup-btn" type="button">Cerrar</button>
    </div>
  </div>
</div>
