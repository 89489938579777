import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrl: './new-password.component.scss'
})
export class NewPasswordComponent {

  dni: string = '';
  password: string = '';
  password2: string = '';
  viewHeight: number = 0;
  minHeight:number = 475;

  validToken: Boolean = false

  errorMessage: string = '';

  recover: Boolean = false;
  recover2Form: FormGroup;
  destroyRef  =inject(DestroyRef)



  constructor(
    public commonService: CommonService,
    public apiService : ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router
  ) {
    this.recover2Form = this.fb.group({
      dni: ['', Validators.required],
      password: ['', Validators.required],
      password2: ['', Validators.required],

    });
  }

  ngOnInit(): void {
    this.setComponentHeight()
    const token = this.route.snapshot.queryParamMap.get('recover');
    console.log(token)
    console.log(this.recover)
    if (token){
      this.apiService.validToken(token).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          if (res) {
            if(res.code==210){
              this.validToken=true
            } else{
            }
          } else {

          }
        },
        error: (error) => {

        },
      });
    }
  }

  ngAfterViewInit() {
    const delay = 0; // <= eso es ser rapido
    setTimeout(() => {
      this.commonService.espacioComponente()
     }, delay);

  }

  isOpen2 = false;
  openPopup2(): void {
      this.isOpen2 = true;
  }
  closePopup2(): void {
    this.isOpen2 = false;
    this.router.navigate(['/login']);

}

  recoverPassword2() {
    this.errorMessage = ''
    if (this.commonService.isValidSpanishDNI(this.dni)){
      if(this.password===this.password2){
        const token = this.route.snapshot.queryParamMap.get('recover');
        if(token){
          this.apiService.resetPassPart2(this.dni, this.password, token).pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (res) => {
              if (res) {
                if(res.code==210){
                  this.openPopup2();
                  const delay = 0; // <= eso es ser rapido
                  setTimeout(() => {
                    this.errorMessage = ''
                  }, delay);
                } if(res.code==553){
                  this.errorMessage = 'Contraseña demasiado corta';
                } else{
                  this.errorMessage = 'Error al cambiar la contraseña';

                }
              } else {
                this.errorMessage = 'Error al cambiar la contraseña';

              }
            },
            error: (error) => {
              this.errorMessage = 'Error al cambiar la contraseña';

            },
          });
        }

      } else{
        this.errorMessage = 'Las contraseñas no coinciden'
      }
    } else{
      this.errorMessage = 'DNI inválido';
    }

  }

  setComponentHeight(){
    const header = document.getElementById('header');
    const footer = document.getElementById('footer');
    const component = document.getElementById('componente');

    if (header && component) {
      const headerHeight = header.offsetHeight;
      const footerHeight = footer ? footer.offsetHeight : 0;
      this.viewHeight = window.innerHeight - headerHeight - footerHeight;
      if(this.viewHeight<this.minHeight){
        component.style.minHeight = this.minHeight+'px';    // set height

      } else{
        component.style.minHeight = `${this.viewHeight}px`;    // set height

      }
    }
  }
}
