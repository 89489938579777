
export class Contact{

    name: string
    email: string
    phone: string
    subject: string
    message: string


    constructor() {
      this.name = '';
      this.email = '';
      this.phone = '';
      this.subject = '';
      this.message = ''
    }

   }
