import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-solicitudes-prestacion-detalle',
  templateUrl: './solicitudes-prestacion-detalle.component.html',
  styleUrl: './solicitudes-prestacion-detalle.component.scss'
})
export class SolicitudesPrestacionDetalleComponent implements OnInit {

  order: string = "";

  constructor(
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
    this.route.queryParams
    .pipe(
      filter((params: any) => params.submenu)
    )
    .subscribe((params: any) => {

      this.order = params.submenu;

    });

  }



}
