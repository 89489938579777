
export class Situacion{

    id: number
    nombre: string

    constructor() {
      this.id = 0
      this.nombre = ''

    }

   }
