import { AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild, DestroyRef, inject} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from '../../../core/services/common.service';
import { ApiService } from '../../../core/services/api.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Prestacion } from '../../../shared/models/prestacion.model';
import { TipoPrestacion } from '../../../shared/models/tipoPrestacion.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';



import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver-es';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-prestacion',
  templateUrl: './prestacion.component.html',
  styleUrl: './prestacion.component.scss'
})
export class PrestacionComponent implements AfterViewInit, OnInit, AfterViewChecked{

  readonly range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  destroyRef = inject(DestroyRef);
  userName:string =''
  userNumber: number = 0

  selectForm: FormGroup;
  filtroTipo: string = '';
  filtroFecha: string = '';
  filtroRango: string = '';
  sortColumn: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';

  private logoBase64: string = '';

  private logoWidth: number = 0;  // Ancho original de la imagen
  private logoHeight: number = 0; // Alto original de la imagen

  constructor(
    public commonService: CommonService,
    public apiService: ApiService,
    private fb: FormBuilder,
  ) {
    this.selectForm = this.fb.group({
    });
    this.loadImageAsBase64('  ../../../../../../assets/images/montepio.png').then(base64 => {
      this.logoBase64 = base64;
      const img = new Image();
      img.src = base64;
      img.onload = () => {
        this.logoWidth = img.width;
        this.logoHeight = img.height;
      };
    });
  }



  prestaciones: Prestacion[] = [];
  tipoPrestaciones: TipoPrestacion[] = [];

  noPrestaciones: Boolean = false;

  loaded: Boolean = false;
  displayedColumns: string[] = ['fecha_pago', 'tipo', 'importe_neto'];
  dataSource = new MatTableDataSource<any>();


  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit(): void {
    this.commonService.espacioComponente();
    this.getData();
    this.userName = JSON.parse(localStorage.getItem("currentUser") || '').name
    this.userName = this.userName.toLowerCase().replace(/(?:^|\s)\S/g, match => match.toUpperCase())
    this.commonService.nombre = this.userName

     // Escuchar cambios en el rango de fechas
    this.range.valueChanges.subscribe(() => {
      this.applyDateFilter();  // Aplica el filtro cuando cambia el rango de fechas
    });
  }

  ngAfterViewInit() {
    const delay = 0; // <= eso es ser rapido
    setTimeout(() => {
      this.commonService.espacioComponente();
      this.dataSource.paginator = this.paginator;

    }, delay);
  }

  ngAfterViewChecked() {
    if (this.dataSource && this.paginator && !this.dataSource.paginator) {
      this.dataSource.paginator = this.paginator;

      const componentPaginator = document.querySelector(".mat-mdc-paginator-range-label")
      if (componentPaginator && componentPaginator instanceof HTMLElement){
        if(window.innerWidth<767){
          componentPaginator.style.display='none'

        }

      }

      const component = document.getElementById('select');
          if (component){
            const primerElemento = component.children[0]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
            if (primerElemento && primerElemento instanceof HTMLElement){
              primerElemento.style.backgroundColor = 'white';
            }
            const segundoElemento = component.children[1]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
            if (segundoElemento && segundoElemento instanceof HTMLElement){
              segundoElemento.style.display = 'none';
            }
          }
      const component2 = document.getElementById('range-date');
          if (component2){
            const primerElemento = component2.children[0]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
            if (primerElemento && primerElemento instanceof HTMLElement){
              primerElemento.style.backgroundColor = 'white';
            }
            const segundoElemento = component2.children[1]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
            if (segundoElemento && segundoElemento instanceof HTMLElement){
              segundoElemento.style.display = 'none';
            }
          }

    }
  }

  cambioScroll(){
    const componentTipo = document.querySelector('[role="listbox"]');
    if (componentTipo && componentTipo instanceof HTMLElement){
      componentTipo.style.fontSize='small'
      componentTipo.style.height = 'auto';
      componentTipo.style.maxHeight = 'none';
    }
  }

  applyFilter(filterValue: string | null) {
    const { start, end } = this.range.value;

    this.dataSource = new MatTableDataSource<any>(this.prestaciones.map(({ fecha_pago, importe_neto, tipo }) => ({ fecha_pago, importe_neto, tipo: tipo.nombre })));
    this.filtroFecha=''
    if (filterValue !== null) {
      if(this.filtroTipo){
        this.applyTipoFilter(this.filtroTipo)
        this.dataSource.data = this.dataSource.filteredData
      } else{
        this.dataSource = new MatTableDataSource<any>(this.prestaciones.map(({ fecha_pago, importe_neto, tipo }) => ({ fecha_pago, importe_neto, tipo: tipo.nombre })));
      }
      this.filtroFecha = filterValue.trim().toLowerCase();
      this.dataSource.filter = this.filtroFecha
    } else {
      this.dataSource = new MatTableDataSource<any>(this.prestaciones.map(({ fecha_pago, importe_neto, tipo }) => ({ fecha_pago, importe_neto, tipo: tipo.nombre })));
      this.dataSource.filter = '';
      this.filtroFecha = ''
      this.applyTipoFilter(this.filtroTipo)
    }

    if(start && end){
      this.dataSource = new MatTableDataSource<any>(
        this.dataSource.filteredData.filter((prestacion) => {
          const fechaPago = new Date(prestacion.fecha_pago);
          return fechaPago >= start && fechaPago <= end;
        }).map(({ fecha_pago, importe_neto, tipo }) => ({
          fecha_pago,
          importe_neto,
          tipo
        }))
      );
    }
  }


  applyTipoFilter(tipo: string | null) {
    const { start, end } = this.range.value;

    this.dataSource = new MatTableDataSource<any>(this.prestaciones.map(({ fecha_pago, importe_neto, tipo }) => ({ fecha_pago, importe_neto, tipo: tipo.nombre })));
    this.filtroTipo=''
    if ((tipo !== null) && (tipo!=='all')) {
      if(this.filtroFecha){
        this.applyFilter(this.filtroFecha)
        this.dataSource.data = this.dataSource.filteredData
      } else{
        this.dataSource = new MatTableDataSource<any>(this.prestaciones.map(({ fecha_pago, importe_neto, tipo }) => ({ fecha_pago, importe_neto, tipo: tipo.nombre })));
      }
      this.filtroTipo = tipo.trim().toLowerCase();
      this.dataSource.filter = this.filtroTipo
    } else {
      this.dataSource = new MatTableDataSource<any>(this.prestaciones.map(({ fecha_pago, importe_neto, tipo }) => ({ fecha_pago, importe_neto, tipo: tipo.nombre })));
      this.dataSource.filter = '';
      this.filtroTipo = ''
      this.applyFilter(this.filtroFecha)
    }
    if(start && end){
      this.dataSource = new MatTableDataSource<any>(
        this.dataSource.filteredData.filter((prestacion) => {
          const fechaPago = new Date(prestacion.fecha_pago);
          return fechaPago >= start && fechaPago <= end;
        }).map(({ fecha_pago, importe_neto, tipo }) => ({
          fecha_pago,
          importe_neto,
          tipo
        }))
      );
    }
  }

  applyDateFilter() {
    const { start, end } = this.range.value;
    this.applyFilter(this.filtroFecha)
    this.applyTipoFilter(this.filtroTipo)

    if(start && end){
      this.dataSource = new MatTableDataSource<any>(
        this.dataSource.filteredData.filter((prestacion) => {
          const fechaPago = new Date(prestacion.fecha_pago);
          return fechaPago >= start && fechaPago <= end;
        }).map(({ fecha_pago, importe_neto, tipo }) => ({
          fecha_pago,
          importe_neto,
          tipo
        }))
      );
    }
  }



  // Método para formatear el importe
  formatCurrency(value: number): string {
    if (value == null) {
      return '';
    }

    // Convertir el número a una cadena con dos decimales
    const parts = value.toFixed(2).split('.');

    // Formatear la parte entera con puntos como separadores de miles
    const integerPart = parts[0];
    const decimalPart = parts[1];

    // Insertar los puntos en la parte entera
    const integerFormatted = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Formar el resultado final con coma como separador decimal
    return `${integerFormatted},${decimalPart} €`;
  }

  getData() {
    this.apiService.getPrestaciones().pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: (res) => {
        if (res) {
          this.prestaciones = res;
          if(this.prestaciones.length==0){
            this.noPrestaciones=true
          }
          this.dataSource = new MatTableDataSource<any>(this.prestaciones.map(({ fecha_pago, importe_neto, tipo }) => ({ fecha_pago, importe_neto, tipo: tipo.nombre })));
          this.loaded = true;
        } else {
          console.warn('Empty response received');
        }
      },
      error: (error) => {
        console.error('Error fetching prestaciones: ', error);
      },
    });
    this.apiService.getTipoPrestaciones().pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: (res) => {
        if (res) {
          this.tipoPrestaciones = res;
        } else {
          console.warn('Empty response received');
        }
      },
      error: (error) => {
        console.error('Error fetching prestaciones: ', error);
      },
    });
  }

  sortData(column: string) {
    const isAsc = this.sortColumn === column && this.sortDirection === 'asc';
    this.sortDirection = isAsc ? 'desc' : 'asc';
    this.sortColumn = column;

    this.dataSource.data = this.dataSource.filteredData.map(({ fecha_pago, importe_neto, tipo }) => ({ fecha_pago, importe_neto, tipo })).sort((a, b) => {
      const isAsc = this.sortDirection === 'asc';
      switch (column) {
        case 'fecha_pago': return this.compare(a.fecha_pago, b.fecha_pago, isAsc);
        case 'tipo': return this.compare(a.tipo, b.tipo, isAsc);
        case 'importe_neto': return this.compare(a.importe_neto, b.importe_neto, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string | undefined, b: number | string | undefined, isAsc: boolean) {
    if (a === undefined || b === undefined) return 0;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  exportarAExcel() {
    // Convertir los datos de la tabla a un formato adecuado para Excel
    const datosFiltrados = this.prestaciones
    .filter(prestacion => {
      const tipoSeleccionado = this.filtroTipo; // Debes asegurarte de tener una variable que contenga el tipo seleccionado
      const fechaSeleccionado = this.filtroFecha; // Debes asegurarte de tener una variable que contenga el tipo seleccionado
      const { start, end } = this.range.value;
      const fechaPago = new Date(prestacion.fecha_pago);
      const fechaEnRango = (!start || !end || (fechaPago >= start && fechaPago <= end));
      // Filtro por tipo (si hay un tipo seleccionado)
      const tipoValido = !tipoSeleccionado || prestacion.tipo.nombre === tipoSeleccionado;
      const fechaValido = !fechaSeleccionado || prestacion.fecha_pago.includes(fechaSeleccionado);

      return fechaEnRango && tipoValido && fechaValido;
    })
    .map(({ fecha_pago, importe_neto, tipo, importe_bruto, retencion }: any) => ({
      'Fecha de Pago': fecha_pago,
      'Tipo de Prestación': tipo.nombre,
      'Importe bruto': importe_bruto,
      'Retención': retencion,
      'Importe neto': importe_neto,

  }));

    // Agregar una fila para el total al final
    datosFiltrados.push({
      'Fecha de Pago': '',
      'Tipo de Prestación': '',
      'Importe bruto': '',
      'Retención': 'TOTAL:',
      'Importe neto':''
    });

    // Crear una hoja de cálculo
    const hojaDeCalculo: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datosFiltrados);

    // Ajustar el ancho de las columnas
    const columnas = [
      { header: 'Fecha de Pago', key: 'Fecha de Pago', width: 20 },
      { header: 'Tipo de Prestación', key: 'Tipo de Prestación', width: 20 },
      { header: 'Importe neto', key: 'Importe neto', width: 15 },
      { header: 'Retención', key: 'Retención', width: 15 },
      { header: 'Importe bruto', key: 'Importe bruto', width: 15 }

    ];

    hojaDeCalculo['!cols'] = columnas.map(col => ({ width: col.width }));

    // Aplicar formato de moneda a la columna 'Importe'
    const formatoMoneda = { numFmt: '#,##0.00 €' }; // Formato de moneda en euros con símbolo al final
    const columnasMoneda = ['C', 'D', 'E']; // Las columnas que deben tener formato de moneda

    // Aplica el formato de moneda a todas las celdas de las columnas C, D y E
    columnasMoneda.forEach(columna => {
      for (let i = 1; i <= datosFiltrados.length; i++) {
        const celda = `${columna}${i + 1}`; // Ignoramos la fila del encabezado
        hojaDeCalculo[celda] = {
          ...(hojaDeCalculo[celda] || {}),
          z: formatoMoneda.numFmt
        };
      }
    });

     // Obtener el número de filas de datos (sin contar el encabezado)
    const totalRows = datosFiltrados.length;

    // Insertar la fórmula de suma en la última fila de la columna E (Importe neto)
    const celdaTotalImporteNeto = `E${totalRows + 1}`; // Celda para la suma en la columna E
    const rangoImporteNeto = `E2:E${totalRows}`; // Rango de celdas a sumar (sin encabezado)
    hojaDeCalculo[celdaTotalImporteNeto] = { f: `SUM(${rangoImporteNeto})` }; // Insertar fórmula de suma

    // Aplicar formato de moneda a la celda del total en la columna E
    hojaDeCalculo[celdaTotalImporteNeto] = {
      ...(hojaDeCalculo[celdaTotalImporteNeto] || {}),
      z: formatoMoneda.numFmt // Aplicar formato de moneda
    };

    // Crear un libro de trabajo y agregar la hoja
    const libroDeTrabajo: XLSX.WorkBook = {
      Sheets: { 'Prestaciones': hojaDeCalculo },
      SheetNames: ['Prestaciones']
    };

    // Generar el archivo Excel en formato binario
    const excelBuffer: any = XLSX.write(libroDeTrabajo, { bookType: 'xlsx', type: 'array' });

    // Guardar el archivo Excel
    this.guardarComoExcel(excelBuffer, 'Prestaciones');
  }

  private guardarComoExcel(buffer: any, nombreArchivo: string) {
    const blob: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `${nombreArchivo}.xlsx`);
  }

  private loadImageAsBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  exportarAPDF() {
    const doc = new jsPDF();

    const addHeader = () => {
      if (this.logoBase64) {
        // Utilizar el tamaño original de la imagen
        doc.addImage(this.logoBase64, 'PNG', 14, 10, this.logoWidth * 0.2, this.logoHeight * 0.2); // Ajusta el tamaño según sea necesario
      }
      doc.setFontSize(12);
      doc.setTextColor(40);

    };

    const addFooter = (data:any)=> {
      const footerText = 'Página ' + data.pageNumber;
      const pageWidth = doc.internal.pageSize.width;
      const margin = 10; // Margen desde el borde de la página

      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.text(footerText, pageWidth - margin - 20, doc.internal.pageSize.height - margin);
    }

    // Función para agregar encabezado a cada página
    const addHeaderToEachPage = (data: any) => {
      addHeader();
      addFooter(data);

    };

    // Configuración de alineación de columnas
    const columnStyles = {
      2: { // Columna 2 (Importe)
        halign: 'right' // Alinear a la derecha
      },
      3: { // Columna 2 (Importe)
        halign: 'right' // Alinear a la derecha
      },
      4: { // Columna 2 (Importe)
        halign: 'right' // Alinear a la derecha
      }
    };


    if(this.userNumber===0){
      const user = localStorage.getItem('currentUser')
      if(user){
        this.userNumber = JSON.parse(user).number
      }
    }

    // Título del documento
    doc.text('Prestaciones - ' + this.userName + ' ('+this.userNumber + ')', 14, 40);

    // Aquí asegúrate de obtener los datos de prestaciones con la columna adicional
    const datosFiltrados = this.prestaciones
    .filter(prestacion => {
      const tipoSeleccionado = this.filtroTipo; // Debes asegurarte de tener una variable que contenga el tipo seleccionado
      const fechaSeleccionado = this.filtroFecha; // Debes asegurarte de tener una variable que contenga el tipo seleccionado
      const { start, end } = this.range.value;
      const fechaPago = new Date(prestacion.fecha_pago);
      const fechaEnRango = (!start || !end || (fechaPago >= start && fechaPago <= end));
      // Filtro por tipo (si hay un tipo seleccionado)
      const tipoValido = !tipoSeleccionado || prestacion.tipo.nombre === tipoSeleccionado;
      const fechaValido = !fechaSeleccionado || prestacion.fecha_pago.includes(fechaSeleccionado);

      return fechaEnRango && tipoValido && fechaValido;
    })
    .map(({ fecha_pago, importe_neto, tipo, importe_bruto, retencion }) => [
      fecha_pago,
      tipo.nombre,
      this.formatCurrency(importe_bruto),
      this.formatCurrency(retencion),
      this.formatCurrency(importe_neto),

    ]);

    // Calcular el importe total
    const totalImporte = this.dataSource.filteredData.reduce((acc, { importe_neto }) => acc + importe_neto, 0);
    const totalImporteFormatted = this.formatCurrency(totalImporte);

    // Configuración de las columnas
    const encabezados = [['Fecha de Pago', 'Tipo de Prestación', { content: 'Importe bruto', styles: { halign: 'right' } }, { content: 'Retención', styles: { halign: 'right' } }, { content: 'Importe neto', styles: { halign: 'right' } }]];
    // Agregar la tabla al PDF
    (doc as any).autoTable({
      startY: 45,
      didDrawPage: addHeaderToEachPage,
      head: encabezados,
      body: datosFiltrados,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [29, 82, 115] }, // Color de la cabecera
      columnStyles: columnStyles, // Aplicar estilos de columna
      theme: 'striped', // Estilo de la tabla
      margin: { top: 45 } // Margen superior de 50
    });

    // Obtener la posición actual de Y después de la tabla
    const finalY = (doc as any).lastAutoTable.finalY || 45;

     // Calcular la posición en el eje X (derecha)
    const pageWidth = doc.internal.pageSize.width;
    const marginRight = 14; // Margen derecho
    const totalX = pageWidth - marginRight;

    // Agregar el total después de la tabla
    doc.setFontSize(10);
    doc.text(`TOTAL: ${totalImporteFormatted}`, totalX, finalY + 10, { align: 'right' });

    // Guardar el PDF
    doc.save('Prestaciones.pdf');
  }


}
