import { Contact } from './../../shared/models/contact.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Reclamacion } from '../../shared/models/reclamacion.model';

// const baseUrl = 'https://asociadosmontepio.gruposca.net/API-1/';
// const baseUrl = 'http://localhost:8080/API-1/';
// const baseUrl = 'http://192.168.2.148:8080/';



const baseUrl = environment.baseURL;


@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient) { }

  // getData(): Observable<any> {
  //   const url = baseUrl + 'posts';
  //   return this.http.get<any>(url);
  // }


  login(dni: string, password: string): Observable<any> {
    const url = baseUrl + 'public/login';
    const body = { dni: dni, password: password };

    // Configuración de los headers
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // Puedes agregar más headers si es necesario
    });

    return this.http.post<any>(url, body, { headers: headers });
  }

  getData(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'apimontepio/asociado/' + user.id;

    return this.http.post<any>(url, null);
  }

  getDataRelamación(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'apimontepio/asociado/min/' + user.id;

    return this.http.post<any>(url, null);
  }

  getAportaciones(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'apimontepio/aportaciones/' + user.id;

    return this.http.post<any>(url, null);
  }

  getPrestaciones(): Observable<any> {0.0
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'apimontepio/prestaciones/' + user.id;

    return this.http.post<any>(url, null);
  }

  getDerechos(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'apimontepio/derechos/' + user.id;

    return this.http.post<any>(url, null);
  }

  getTipoAportaciones(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'apimontepio/tipoaportaciones';

    return this.http.get<any>(url);
  }

  getTipoPrestaciones(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'apimontepio/tipoprestaciones';

    return this.http.get<any>(url);
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const url = baseUrl + 'apimontepio/changepass';
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const body = { id: user.id, oldPassword: oldPassword, newPassword: newPassword};

    return this.http.post<any>(url, body);
  }

  contactUs(contact: Contact): Observable<any> {
    const url = baseUrl + 'apimontepio/emailContact';
    const {name, email, phone, subject, message} = contact
    const body = { name, email, phone, subject, message };

    return this.http.post<any>(url, body);
  }

  reclamacion(reclamacion: Reclamacion): Observable<any> {
    const url = baseUrl + 'apimontepio/reclamacion/alta';
    const {name, dni, domicilio, phone, email, numeroSocio, fechaQueja, descripcionQueja, tipoQueja, reclamacionAnterior, archivo1_nombre, archivo1_data, archivo2_nombre, archivo2_data, archivo3_nombre, archivo3_data, solicitudReclamante} = reclamacion
    const body = {name, dni, domicilio, phone, email, numeroSocio, fechaQueja, descripcionQueja, tipoQueja, reclamacionAnterior, archivo1_nombre, archivo1_data, archivo2_nombre, archivo2_data, archivo3_nombre, archivo3_data, solicitudReclamante};

    return this.http.post<any>(url, body);
  }

  validToken(token: string): Observable<any> {
    const url = baseUrl + 'public/ValidToken';
    const body = { token: token };

    return this.http.post<any>(url, body);
  }

  resetPassPart1(dni: string, email:string): Observable<any> {
    const url = baseUrl + 'public/ResetPass';
    const body = { dni: dni, email: email };

    return this.http.post<any>(url, body);
  }

  resetPassPart2(dni: string, password:string, token: string): Observable<any> {
    const url = baseUrl + 'public/SavePass';
    const body = { token: token, dni: dni, password: password };

    return this.http.post<any>(url, body);
  }

  getCertificate(tipo: number, year:string): Observable<any>{
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'apimontepio/certificate/pdf'
    const body = { tipo: tipo, year: year };

    return this.http.post<any>(url, body);
  }

  getFechasCertificados(): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const url = baseUrl + 'apimontepio/certificate/years/' + user.id;

    return this.http.get<any>(url);
  }

}
