import { CookieService } from './../../../core/services/cookie.service';
import { AfterViewInit, Component, OnInit, DestroyRef, inject, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../core/services/auth.service';
import { CommonService } from '../../../core/services/common.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, AfterViewInit {

  dni: string = '';
  password: string = '';
  errorMessage: string = '';
  errorMessagePassword: string = '';
  errorMessageDni: string = '';

  viewHeight: number = 0;
  minHeight:number = 475;

  loginForm: FormGroup;

  env = environment.env


  destroyRef = inject(DestroyRef)

  constructor(public translate: TranslateService,
              public router: Router,
              public authService: AuthService,
              public cookieService: CookieService,
              public commonService: CommonService,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef) {
    this.cookieService.removeAllCookies();
    localStorage.removeItem('currentUser');
    this.loginForm = this.fb.group({
      dni: ['', Validators.required],
      password: ['', Validators.required],

    })
  }

  ngOnInit(): void {
    this.authService.error$.pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(errorMessage => {
      this.errorMessage = errorMessage;
    });
  }

  ngAfterViewInit() {
    const delay = 0;
    setTimeout(() => {
      this.commonService.espacioComponente();
      this.setComponentHeight()
      this.cdr.detectChanges(); // Trigger change detection manually
      console.log(this.dni, this.password)
    }, 100);
  }

  onLogin() {
    this.errorMessage = ''
    this.errorMessageDni = ''
    this.errorMessagePassword = ''
    if(this.dni == ''){
      this.errorMessageDni = 'Campo obligatorio'
      return
    }
    if(this.password == ''){
      this.errorMessagePassword = 'Campo obligatorio'
      return
    }
    if (this.commonService.isValidSpanishDNI(this.dni)){
      this.authService.login(this.dni, this.password)
      const component = document.getElementById('componente');
      if(component){
        component.style.minHeight = this.minHeight+'px';    // set height

      }

    } else{
      this.errorMessage = 'DNI invalido';
    }

  }

  setComponentHeight(){
    const header = document.getElementById('header');
    const footer = document.getElementById('footer');
    const component = document.getElementById('componente');

    if (header && component) {
      const headerHeight = header.offsetHeight;
      const footerHeight = footer ? footer.offsetHeight : 0;
      this.viewHeight = window.innerHeight - headerHeight - footerHeight;
      if(this.viewHeight<this.minHeight){
        component.style.minHeight = this.minHeight+'px';    // set height

      } else{
        component.style.minHeight = `${this.viewHeight}px`;    // set height

      }
    }
  }
}
