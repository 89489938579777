import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './pages/auth/login-page/login-page.component';
import { UserDataComponent } from './pages/user-data/user-data/user-data.component';
import { InfoComponent } from './pages/info-pages/information/info.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ConsolidatedRightsComponent } from './pages/user-data/contributions/consolidated-rights/consolidated-rights.component';
import { MyContributionsComponent } from './pages/user-data/contributions/my-contributions/my-contributions.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AuthGuard } from './core/guard/auth.guard';
import { CertificatesComponent } from './pages/info-pages/certificates/certificates.component';
import { SolicitudesComponent } from './pages/info-pages/solicitudes-prestacion/solicitudes-prestacion.component';
import { PrestacionComponent } from './pages/info-pages/prestaciones/prestacion.component';
import { RecoverPasswordComponent } from './pages/auth/recover-password/recover-password.component';
import { SolicitudesPrestacionDetalleComponent } from './pages/info-pages/solicitudes-prestacion-detalle/solicitudes-prestacion-detalle.component';
import { NewPasswordComponent } from './pages/auth/new-password/new-password.component';

const routes: Routes = [

  {path: "",redirectTo: "datos", pathMatch: "full"},
  {path: 'login', component: LoginPageComponent},
  {path: 'info', component: InfoComponent, canActivate: [AuthGuard]},
  {path: 'datos', component: UserDataComponent, canActivate: [AuthGuard]},
  {path: 'contacto', component: ContactComponent, canActivate: [AuthGuard]},
  {path: 'derechosEconomicos', component: ConsolidatedRightsComponent, canActivate: [AuthGuard]},
  {path: 'misAportaciones', component: MyContributionsComponent, canActivate: [AuthGuard]},
  {path: 'certificados', component: CertificatesComponent, canActivate: [AuthGuard]},
  {path: 'solicitudesPrestacion', component: SolicitudesComponent, canActivate: [AuthGuard]},
  {path: 'solicitudesPrestacionDetalle', component: SolicitudesPrestacionDetalleComponent, canActivate: [AuthGuard]},
  {path: 'prestacion', component: PrestacionComponent, canActivate: [AuthGuard]},
  {path: 'recuperarClave', component: RecoverPasswordComponent},
  {path: 'nuevaClave', component: NewPasswordComponent},
  {path: '**', component: NotFoundComponent, canActivate: [AuthGuard]} // This line will remain down from the whole pages component list

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
