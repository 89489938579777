<!-- Page Banner -->
<!-- <div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-banner-content">
            <h2>Login</h2>
            <ul class="pages-list">
                <li><a href="index.html">Home</a></li>
                <li>Login</li>
            </ul>
        </div>
    </div>
</div> -->

<!-- Login -->
<div class="login-area login-background">
  <div class="container" id="element-to-click">
      <div class="login-form" autocomplete="off">
          @if(env == 'test'){
            <h4>Acceso al área privada (TEST)</h4>
          }
          @if(env == 'prod'){
            <h4>Acceso al área privada</h4>
          }
          <form  [formGroup]="loginForm">
              <div class="form-group">
                  <label>DNI</label>
                  <input type="text" formControlName="dni" class="form-control" [(ngModel)]="dni" placeholder="DNI" name="dni">
                  <div *ngIf="errorMessageDni" class="error-message col-lg-6 col-md-6">
                    {{ errorMessageDni }}
                </div>
              </div>
              <div class="form-group">
                  <label>Contraseña</label>
                  <input type="password" formControlName="password" class="form-control" [(ngModel)]="password" placeholder="Contraseña" name="password">
                  <div *ngIf="errorMessagePassword" class="error-message col-lg-6 col-md-6">
                    {{ errorMessagePassword }}
                </div>
              </div>
              <div class="row align-items-center">
                  <div *ngIf="errorMessage" class="error-message col-lg-6 col-md-6">
                      {{ errorMessage }}
                  </div>
                  <div *ngIf="!errorMessage" class="col-lg-6 col-md-6">
                  </div>
                  <div class="col-lg-6 col-md-6 lost-your-password-wrap">
                      <a routerLink="/recuperarClave" class="lost-your-password">¿Olvidaste tu contraseña?</a>
                  </div>
              </div>
              <button type="button" class="default-btn" (click)="onLogin()">Iniciar Sesión</button>
          </form>

      </div>
  </div>
</div>
