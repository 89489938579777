import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }


  // sessionStorage.setItem('userSessionId', new Date().getTime().toString());


  createCookie(clave: string, valor: string, seconds: number) {
    var date = new Date()
    date.setTime(date.getTime() + (seconds * 1000))
    var expires = 'expires=' + date.toUTCString()
    document.cookie = clave + '=' + valor + '; ' + expires
  }

  getCookie(clave: string) {
    var name = clave + '='
    var ca = document.cookie.split(';')
    for(var i = 0 ; i < ca.length ; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1)
        if (c.indexOf(name) === 0) return c.substring(name.length,c.length)
    }
    return ''
  }

  checkCookie(clave: string, valor: string, seconds: number) {
    var storedValue = this.getCookie(clave)
    if (storedValue !== '') {
        // Cookie exists.
        var date = new Date()
        date.setTime(date.getTime() + (seconds * 1000))
        var expires = 'expires=' + date.toUTCString()
        document.cookie = clave + '=' + valor + '; ' +  expires
    }else{
        // Cookie does not exists.
        this.createCookie(clave, valor, seconds)
    }
  }

  updateCookieExpiration() {
    var pairs = document.cookie.split(';')
    var date = new Date()
    date.setTime(date.getTime() + (this.getCookiesExpirationTime('cookiesExpirationTime')) * 1000)
    var expires = 'expires=' + date.toUTCString()
    for(var i = 0 ; i < pairs.length ; i++){
        var pair = pairs[i].split('=')
        if( pair[0].trim() === 'auth_' + sessionStorage.getItem('userSessionId')){
          document.cookie = pair[0] + '=' + pair[1] + '; ' + expires
        }
    }
  }

  removeAllCookies() {
    var pairs = document.cookie.split('; ')
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=')
        document.cookie = pair[0] + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
    }
  }

  removeCookie(clave: string) {
    document.cookie = clave + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
  }

  //Se guarda el tiempo en sesión para poder actualizarlo con la función updateCookieExpiration()
  configureCookieExpirationTime(value: string){
    sessionStorage.setItem('cookiesExpirationTime', value)
  }

  getCookiesExpirationTime(clave: string){
    let sessionValue = sessionStorage.getItem(clave)
    if (sessionValue){
      return parseInt(sessionValue)
    }else{
      return 0
    }
  }

}
