<div [ngClass]="{'top-header-area': env == 'test', 'top-header-area-prod': env == 'prod'}">
    <div class="container">
        <div class="row align-items-center">
            <div  [ngClass]="{'col-12': isLoginPage(), 'col-xl-6 col-lg-7 col-md-12 noLogin': !isLoginPage()}">
                <ul class="top-header-information">
                    <li>
                        <i class='bx bxs-map'></i>
                        Gran Vía de Don Diego López de Haro, 81 - 5º dpto 4 - 48011 Bilbao - Bizkaia
                    </li>
                    <!-- <li>
                        <i class='bx bx-envelope-open'></i>
                        <a href="mailto:hello&#64;inba.com">contacto&#64;montepioigualatorio.com</a>
                    </li> -->
                </ul>
            </div>
            <div class="col-xl-6 col-lg-5 col-md-12" *ngIf="!isLoginPage()">

              <ul class="middle-header-content">
                <li>
                  <p>{{this.commonService.nombre}}</p>
                </li>
                  <li>
                    <i class="flaticon-042-user"></i>
                  </li>
                  <li>
                    <i class=" logout fa-solid fa-arrow-right-from-bracket" (click)="onLogout()"></i>
                  </li>
              </ul>

                <!-- <ul class="top-header-optional"> -->
                    <!-- <li> -->
                        <!-- <a href="https://www.facebook.com/" target="_blank">
                            <i class='bx bxl-facebook'></i>
                        </a>
                        <a href="https://twitter.com/?lang=en" target="_blank">
                            <i class='bx bxl-twitter'></i>
                        </a>
                        <a href="https://www.linkedin.com/" target="_blank">
                            <i class='bx bxl-linkedin'></i>
                        </a>
                        <a href="https://www.instagram.com/" target="_blank">
                            <i class='bx bxl-instagram'></i>
                        </a> -->

                    <!-- </li> -->
                    <!-- <li class="languages-list">
                        <select>
                            <option value="1">English</option>
                            <option value="2">العربيّة</option>
                            <option value="3">Deutsch</option>
                            <option value="3">Português</option>
                            <option value="3">简体中文</option>
                        </select>
                    </li> -->
                <!-- </ul> -->
            </div>
        </div>
    </div>
</div>
