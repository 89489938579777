import { CookieService } from './cookie.service';
import { Injectable, DestroyRef, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';
import { Login } from '../../shared/models/login.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  mensaje: string = ''
  error$: Subject<string> = new Subject<string>(); // Subject para emitir mensajes de error

  destroyRef = inject(DestroyRef)

  constructor(private router: Router,
    public commonService: CommonService,
    public apiService: ApiService,
    public cookieService: CookieService
  ) { }




  login(username: string, password: string): void {
    this.apiService.login(username, password).pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: (res) => {
        if (res) {
            const loginData = new Login(res.id, res.name, res.token, res.number, res.situacion);
            localStorage.setItem('currentUser', JSON.stringify({ id: loginData.id, name: loginData.name, number: loginData.number, situacion: loginData.situacion }));
            this.cookieService.createCookie("token", loginData.token, 3600);
            this.router.navigate(['/datos']);
        } else {
          this.error$.next('Error en la autenticación');
        }
      },
      error: (error) => {
        this.error$.next('DNI o contraseña incorrectos');
        console.log('prueba')
      },
    });

  }

  logout(): void {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']); // Navigate to the login route
  }

  isLoggedIn(): boolean {
    return localStorage.getItem('currentUser') !== null;
  }

  getCurrentUser(): any {
    return JSON.parse(localStorage.getItem('currentUser') || '{}');
  }

}
