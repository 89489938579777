<!-- Page Banner -->
<!-- <div class="page-banner-area ptb-100">
    <div class="container">
        <div class="page-banner-content">
            <h2>password_lost</h2>
            <ul class="pages-list">
                <li><a href="index.html">Home</a></li>
                <li>password_lost</li>
            </ul>
        </div>
    </div>
</div> -->

<!-- password_lost -->
<div class="password_lost-area password_lost-background">
  <div class="container"     >
      <div class="password_lost-form">
        <a routerLink="/login">
          <i class="flaticon-015-left-arrow"></i>
        </a>

        <h4>Recuperar contraseña</h4>
          <p>¿Has olvidado la contraseña? Por favor, introduce tu email y el DNI y recibirá un link para obtener una nueva contraseña.</p>
          <form [formGroup]="recoverForm">
            <div class="form-group">
              <label>DNI</label>
              <input type="text" required  class="form-control" formControlName="dni" [(ngModel)]="dni" placeholder="dni" name="DNI" >
              <div *ngIf="recoverForm.get('dni')?.errors && recoverForm.get('dni')?.touched" class="text-danger">
                <div *ngIf="recoverForm.get('dni')?.hasError('required')">Campo requerido</div>
              </div>
            </div>
              <div class="form-group">
                <label>Email</label>
                <input type="email" required  class="form-control" formControlName="email" [(ngModel)]="email" placeholder="Email" name="email" >
                <div *ngIf="recoverForm.get('email')?.errors && recoverForm.get('email')?.touched" class="text-danger">
                  <div *ngIf="recoverForm.get('email')?.hasError('required')">Campo requerido</div>
                  <div *ngIf="recoverForm.get('email')?.hasError('pattern')">Correo electrónico no válido</div>
                </div>
              </div>
              <div *ngIf="errorMessage" class="error-message col-lg-8 col-md-6">
                {{ errorMessage }}
              </div>
              <button type="submit" class="default-btn" [disabled]="!dni || !email || !recoverForm.valid || loading"  (click)="recoverPassword()">Recuperar</button>
              <!-- <div class="account-text">
                  <span><a routerLink="/register">Registrarse</a></span>
              </div> -->
          </form>

      </div>
  </div>
</div>

<div class="video-popup" *ngIf="isOpen">
  <div class="popup-inner">
    <h5>Correo enviado</h5>
    <p>Combruebe su correo electrónico para poder cambiar la contraseña.</p>
    <div class="close-button-container">
      <button (click)="closePopup()" class="popup-btn" type="button">Cerrar</button>
    </div>
  </div>
</div>

