<!-- Page Banner -->
<div
    class="page-banner-area ptb-100 four"
    style="background-image: url(../../../../assets/images/certificates.jpg);"
>
    <div class="container">
        <div class="page-banner-content">
            <h2>Certificados</h2>
        </div>
    </div>
</div>


<!-- certificates -->
<div class="hospital-certificates-area ptb-100">
  <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="hospital-certificates-card">
              <div class="title">
                <img src="../../../../assets/images/img.png" alt="image">
                <h3>Asociado/a</h3>
              </div>
              <div class="row">
                <a class="certificates-btn" (click)="getCertificate(2, '')">Descargar</a>
              </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="hospital-certificates-card">
              <div class="title">
                <img src="../../../../assets/images/img.png" alt="image">
                <h3>Fe de vida</h3>
                <p (click)="openPopup2()">+ info</p>
              </div>
              <div class="row">
                <a class="certificates-btn" (click)="getCertificate(9, '')">Descargar</a>
                <!-- <a class="certificates-btn" (click)="getCertificate(2, '')">Descargar</a> -->
              </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="hospital-certificates-card" id="aportaciones">
              <div class="title">
                <img src="../../../../assets/images/img.png" alt="image">
                  <h3>Aportaciones</h3>
              </div>
              <div class="row">
                <mat-form-field id="select" class="select col-6" (click)="cambioScroll()">
                  <mat-label>Fecha</mat-label>
                  <mat-select #tipoPrestacion class="select" [(value)]="selectedFechaAportaciones">
                    <div class="select">
                      @for (fecha of fecAportaciones; track $index) {
                        <mat-option value="{{fecha}}">{{fecha}}</mat-option>
                      }
                    </div>
                  </mat-select>
                </mat-form-field>
                <a class="certificates-btn col-6" (click)="getCertificate(1, selectedFechaAportaciones)">Descargar</a>
              </div>
          </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="hospital-certificates-card">
              <div class="title">
                <img src="../../../../assets/images/img.png" alt="image">
                  <h3>Derechos económicos</h3>
              </div>
              <div class="row">
                <a class="certificates-btn" (click)="getCertificate(3, '')">Descargar</a>
              </div>
          </div>
      </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="hospital-certificates-card" id="invalidez">
              <div class="title">
                <img src="../../../../assets/images/img.png" alt="image">
                  <h3>Prestacion de invalidez</h3>
              </div>
              <div class="row">
                <mat-form-field id="select" class="select col-6" (click)="cambioScroll()">
                  <mat-label>Fecha</mat-label>
                  <mat-select #tipoPrestacion class="select" [(value)]="selectedFechaInvalidez">
                    <div class="select">
                      @for (fecha of fecPrestaInvalidez; track $index) {
                        <mat-option value="{{fecha}}">{{fecha}}</mat-option>
                      }
                    </div>
                  </mat-select>
                </mat-form-field>
                <a class="certificates-btn col-6">Descargar</a>
              </div>
          </div>
      </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="hospital-certificates-card" id="orfandad">
                <div class="title">
                  <img src="../../../../assets/images/img.png" alt="image">
                  <h3>Prestacion de orfandad</h3>
                </div>
                <div class="row">
                  <mat-form-field id="select" class="select col-6" (click)="cambioScroll()">
                    <mat-label>Fecha</mat-label>
                    <mat-select #tipoPrestacion class="select" [(value)]="selectedFechaOrfandad">
                      <div class="select">
                        @for (fecha of fecPrestaOrfandad; track $index) {
                          <mat-option value="{{fecha}}">{{fecha}}</mat-option>
                        }
                      </div>
                    </mat-select>
                  </mat-form-field>
                  <a class="certificates-btn col-6"  (click)="getCertificate(204, selectedFechaOrfandad)">Descargar</a>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="hospital-certificates-card" id="jubilacion">
              <div class="title">
                <img src="../../../../assets/images/img.png" alt="image">
                  <h3>Prestacion de jubilacion</h3>
              </div>
              <div class="row">
                <mat-form-field id="select" class="select col-6" (click)="cambioScroll()">
                  <mat-label>Fecha</mat-label>
                  <mat-select #tipoPrestacion class="select" [(value)]="selectedFechaJubilacion">
                    <div class="select">
                      @for (fecha of fecPrestaJubilacion; track $index) {
                        <mat-option value="{{fecha}}">{{fecha}}</mat-option>
                      }
                    </div>
                  </mat-select>
                </mat-form-field>
                <a class="certificates-btn col-6"  (click)="getCertificate(200, selectedFechaJubilacion)">Descargar</a>
              </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="hospital-certificates-card" id="it">
              <div class="title">
                <img src="../../../../assets/images/img.png" alt="image">
                  <h3>Prestacion de incapacidad temporal</h3>
              </div>
              <div class="row">
                <mat-form-field id="select" class="select col-6" (click)="cambioScroll()">
                  <mat-label>Fecha</mat-label>
                  <mat-select #tipoPrestacion class="select" [(value)]="selectedFechaIT">
                    <div class="select">
                      @for (fecha of fecPrestaIT; track $index) {
                        <mat-option value="{{fecha}}">{{fecha}}</mat-option>
                      }
                    </div>
                  </mat-select>
                </mat-form-field>
                <a class="certificates-btn col-6"  (click)="getCertificate(250, selectedFechaIT)">Descargar</a>
              </div>
          </div>
        </div>

      </div>
  </div>
</div>

<div class="video-popup" *ngIf="isOpen">
  <div class="popup-inner">
    <h5>Error</h5>
    <p>Ha ocurrido un error a la hora de descargar el archivo.</p>
    <div class="close-button-container">
      <button (click)="closePopup()" class="popup-btn" type="button">Cerrar</button>
    </div>
  </div>
</div>


<div class="video-popup infoPopUp" *ngIf="isOpen2">
  <div class="popup-inner">
    <h5>Fe de vida</h5>
    <div class="text">
      <p>Todas las personas beneficiarias del “Montepío del Igualatorio EPSV” que reciben alguna prestación (jubilación, orfandad, invalidez etc.) deben acreditar su "Fe de Vida".</p>
      <p>Para ello, simplemente deben completar el documento adjunto denominado “Acreditación de Fe de Vida”. Se puede presentar este documento junto con una copia del DNI de la persona asociada de la manera que le resulte más conveniente:</p>
      <p>- En persona: en nuestras oficinas ubicadas en Bilbao (Bizkaia), en la calle Gran Vía de Don Diego López de Haro 81, 5ª planta, 4º departamento, de lunes a viernes, de 9:30 a 14:00 horas.</p>
      <p>- Por correo postal: a la dirección Gran Vía de Don Diego López de Haro 81, 5ª planta, 4º departamento (48011 Bilbao).</p>
      <p>-Por correo electrónico: enviando los documentos a asociado&#64;montepioigualatorio.com.</p>
      <p>Esta acreditación debe renovarse <b>anualmente</b> para evitar la suspensión de las prestaciones. Se comunicará a los asociados mediante el envío de notificación personal la apertura de los plazos para la presentación de las acreditaciones.</p>
    </div>
    <div class="close-button-container">
      <button (click)="closePopup2()" class="popup-btn" type="button">Cerrar</button>
    </div>
  </div>
</div>
