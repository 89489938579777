import { ApiService } from './../../../core/services/api.service';
import { AfterViewInit, Component, OnInit, NgModule, AfterViewChecked, ViewEncapsulation, inject, DestroyRef } from '@angular/core';
import { CommonService } from '../../../core/services/common.service';
import { Documento } from '../../../shared/models/documento.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss'],

})
export class CertificatesComponent implements AfterViewInit, OnInit, AfterViewChecked {

  userName: string = ''
  selectedFechaAportaciones: string = ''
  selectedFechaJubilacion: string = ''
  selectedFechaInvalidez: string = ''
  selectedFechaOrfandad: string = ''
  selectedFechaIT: string = ''

  fecAportaciones=[]
  fecPrestaJubilacion=[]
  fecPrestaInvalidez=[]
  fecPrestaOrfandad=[]
  fecPrestaIT=[]

  destroyRef= inject(DestroyRef)

  documento: Documento
  constructor(
    public commonService: CommonService,
    public apiService: ApiService
  ) {
    this.documento = new Documento()
   }

  ngOnInit(): void {
    this.userName = JSON.parse(localStorage.getItem("currentUser") || '').name
    this.userName = this.userName.toLowerCase().replace(/(?:^|\s)\S/g, match => match.toUpperCase())
    this.commonService.nombre = this.userName

    this.apiService.getFechasCertificados().pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          if (res) {
            this.fecAportaciones = res.aportaciones
            this.fecPrestaIT = res.prestaIT
            this.fecPrestaOrfandad = res.prestaOrfandad
            this.fecPrestaInvalidez = res.prestaInvalidez
            this.fecPrestaJubilacion = res.prestaJubilacion

            const jubilacion = document.getElementById('jubilacion')
            const orfandad = document.getElementById('orfandad')
            const it = document.getElementById('it')
            const invalidez = document.getElementById('invalidez')
            const aportaciones = document.getElementById('aportaciones')


            if(this.fecPrestaJubilacion.length==0){
              jubilacion?.classList.add('vacio')
            } else{
              this.selectedFechaJubilacion = this.fecPrestaJubilacion[0];
            }
            if(this.fecPrestaOrfandad.length==0){
              orfandad?.classList.add('vacio')
            } else{
              this.selectedFechaOrfandad = this.fecPrestaOrfandad[0];
            }
            if(this.fecPrestaIT.length==0){
              it?.classList.add('vacio')
            } else{
              this.selectedFechaIT = this.fecPrestaIT[0];
            }
            if(this.fecPrestaInvalidez.length==0){
              invalidez?.classList.add('vacio')
            } else{
              this.selectedFechaInvalidez = this.fecPrestaInvalidez[0];
            }
            if(this.fecAportaciones.length==0){
              aportaciones?.classList.add('vacio')
            } else{
              this.selectedFechaAportaciones = this.fecAportaciones[0];
            }
            console.log(this.fecAportaciones)
          } else {

          }
        },
        error: (error) => {

        },
      });
  }

  ngAfterViewInit() {
    const delay = 0; // <= eso es ser rapido
    setTimeout(() => {
      this.commonService.espacioComponente()
     }, delay);

  }

  ngAfterViewChecked(): void {
    const components = document.getElementsByClassName('select')
    const componentsArray = Array.from(components);

    for (const component of componentsArray){
      if (component.id=='select'){
        const primerElemento = component.children[0]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
        if (primerElemento && primerElemento instanceof HTMLElement){
          primerElemento.style.backgroundColor = 'white';
        }
        const segundoElemento = component.children[1]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
        if (segundoElemento && segundoElemento instanceof HTMLElement){
          segundoElemento.style.display = 'none';
        }
      }
    }

  }



  cambioScroll(){
    const componentTipo = document.querySelector('[role="listbox"]');
    if (componentTipo && componentTipo instanceof HTMLElement){
      componentTipo.style.fontSize='small'
      componentTipo.style.height = 'auto';
      componentTipo.style.maxHeight = 'none';
    }
  }

  getCertificate(tipo: number, fecha: string){
    this.apiService.getCertificate(tipo, fecha).pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: (res) => {
        if (res) {
          if(res.code==210){
            this.documento.data = res.data
            this.documento.name = res.name

            console.log(this.documento)

            const blob = this.dataURItoBlob(this.documento.data)
            const file = new File([blob], this.documento.name, { type: 'application/pdf' });

            if (this.isMobileDevice()){
              const fileURL = URL.createObjectURL(file);
              window.location.href = fileURL;
            } else{
              const link = document.createElement('a');
              link.href = URL.createObjectURL(file);
              link.target = '_blank'; // Abre en una nueva pestaña
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }

            // const link = document.createElement('a');
            // link.href = URL.createObjectURL(file);
            // link.download = this.documento.name;
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);


            // let fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
          }
          if((res.code==560) || (res.code==561)){
            this.openPopup()
          }
        } else {

        }
      },
      error: (error) => {

      },
    });
  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }   const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
 }

 isOpen = false;
 openPopup(): void {
     this.isOpen = true;
 }
 closePopup(): void {
     this.isOpen = false;
 }

 isOpen2 = false;
 openPopup2(): void {
     this.isOpen2 = true;
 }
 closePopup2(): void {
     this.isOpen2 = false;
 }

 isMobileDevice() {
  return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
}


}
