
export class Reclamacion{

    name: string
    dni: string
    domicilio: string
    phone: string
    email: string
    numeroSocio: number
    fechaQueja: string
    descripcionQueja: string
    tipoQueja: string
    reclamacionAnterior: string
    archivo1_nombre : string
    archivo1_data : string
    archivo2_nombre : string
    archivo2_data : string
    archivo3_nombre : string
    archivo3_data : string
    solicitudReclamante: string





    constructor() {
      this.name = ''
      this.dni = ''
      this.domicilio = ''
      this.phone = ''
      this.email = ''
      this.numeroSocio = 0
      this.fechaQueja = ''
      this.descripcionQueja = ''
      this.tipoQueja = ''
      this.reclamacionAnterior = ''
      this.archivo1_nombre = ''
      this.archivo1_data = ''
      this.archivo2_nombre = ''
      this.archivo2_data = ''
      this.archivo3_nombre = ''
      this.archivo3_data = ''
      this.solicitudReclamante = ''
    }

   }
