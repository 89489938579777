<!-- Page Banner -->
<div
    class="page-banner-area ptb-100 four"
    style="background-image: url(../../../../assets/images/solicitudes.jpg);"
>
    <div class="container">
        <div class="page-banner-content">
            <h2>Solicitudes de Prestación</h2>
        </div>
    </div>
</div>

<!-- Home -->
<div class="home-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6 item">
                <div class="single-home-item">
                    <div class="home-image">
                        <a class="d-block">
                            <img src="../../../../assets/images/jubilacion.jpg" alt="home-image">
                            </a>
                    </div>
                    <div class="home-content">
                      <div>
                        <h3>
                          Jubilación
                        </h3>
                        <p>Los asociados tendrán derecho a la prestación de jubilación cuando accedan efectivamente a la jubilación en el régimen correspondiente de la Seguridad Social o similares.</p>

                      </div>
                      <a class="default-btn" (click)="detalle('jubilacion')">+ Info</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 item">
              <div class="single-home-item">
                  <div class="home-image">
                      <a class="d-block">
                          <img src="../../../../assets/images/futuro.jpg" alt="home-image">
                      </a>
                  </div>
                  <div class="home-content">
                    <div>
                      <h3>
                        Fallecimiento
                      </h3>
                      <p>Los asociados tendrán derecho a dejar una prestación en caso de fallecimiento a los beneficiarios que hayas designado, para estar seguros tú y los tuyos, ante cualquier circunstancia del futuro.</p>

                    </div>
                    <a class="default-btn" (click)="detalle('fallecimiento')">+ Info</a>
                  </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 item">
              <div class="single-home-item">
                  <div class="home-image">
                      <a class="d-block">
                          <img src="../../../../assets/images/invalidez.png" alt="home-image">
                      </a>
                  </div>
                  <div class="home-content">
                    <div>
                      <h3>
                        Invalidez
                      </h3>
                      <p>La prestación de invalidez da derecho a una pensión mensual desde que se reconozca la invalidez y hasta cumplir la edad de jubilación ordinaria, en caso de incapacidad permanente.</p>

                    </div>
                    <a class="default-btn" (click)="detalle('invalidez')">+ Info</a>
                  </div>
              </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 item">
            <div class="single-home-item">
                <div class="home-image">
                    <a class="d-block">
                        <img src="../../../../assets/images/orfandaz.jpg" alt="home-image">
                    </a>
                </div>
                <div class="home-content">
                  <div>
                    <h3>
                      Orfandad
                    </h3>
                    <p>Los beneficiarios de esta prestación son los hijos de la persona asociada que queden huérfanos, y sean menores de 23 años o en situación de discapacidad física o intelectual.</p>

                  </div>
                  <a class="default-btn" (click)="detalle('orfandad')">+ Info</a>
                </div>
            </div>

        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 item">
          <div class="single-home-item">
              <div class="home-image">
                  <a class="d-block">
                      <img src="../../../../assets/images/temporal.jpg" alt="home-image">
                  </a>
              </div>
              <div class="home-content">
                <div>
                  <h3>
                    Incapacidad temporal
                  </h3>
                  <p>Solo para profesionales médicos</p>
                </div>
                <a class="default-btn" (click)="detalle('incapacidad-temporal')">+ Info</a>


              </div>
          </div>
      </div>

    </div>
</div>
