import { Component, AfterViewInit, HostListener, ViewChild, ElementRef, Renderer2, AfterContentInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { CookieService } from './core/services/cookie.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements AfterViewInit {
  location: any;
    routerSubscription: any;
    title: any;
    viewHeight: number = 0;
    minHeight:number = 475;



    constructor(
        public router: Router,
        private translate: TranslateService,
        private renderer: Renderer2,
        public cookieService: CookieService,
        
    ) {
      translate.setDefaultLang('es');
      translate.use('es');
    }

    ngOnInit(){
        this.recallJsFuntions();
    }

    isLoginPage(): boolean {
      return (this.router.url === '/login' || this.router.url === '/' || this.router.url.startsWith('/recuperarClave'));
    }

    cookiesAceptadas(): boolean{
      if (this.cookieService.getCookie('cookiesAceptadas')){
        return true
      } else{
        return false
      }
    }


    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    ngAfterViewInit() {
      const delay = 1000; // <= eso es ser rapido

      setTimeout(() => {


      }, delay);
    }

    borrarPaginacion(){
      const componentPaginator = document.querySelector(".mat-mdc-paginator-range-label")
      if (componentPaginator && componentPaginator instanceof HTMLElement){
        if(window.innerWidth<767){
          componentPaginator.style.display='none'

        } else{
          componentPaginator.style.display='block'
        }

      }
    }


    @HostListener('window:resize', ['$event'])
    windowResizecall() {
      this.borrarPaginacion()
  }
}
