import { CommonService } from './../../../../core/services/common.service';
import { CookieService } from './../../../../core/services/cookie.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../core/services/auth.service';
import { environment } from '../../../../../environments/environment';


@Component({
    selector: 'app-top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {
    userName : String = '';
    env = environment.env
    constructor(
        public router: Router,
        public authService: AuthService,
        public cookieService: CookieService,
        public commonService: CommonService
    ) {

     }

    ngOnInit(): void {
      this.userName = this.commonService.nombre
      if(this.userName===''){
        const user = localStorage.getItem('currentUser')
        if(user){
          this.userName = JSON.parse(user).name
        }
      }
    }

    onLogout() {
      this.authService.logout();
      this.cookieService.removeCookie('token');
      // this.cookieService.removeAllCookies();
      this.commonService.nombre = ''
    }

    isLoginPage(): boolean {
      return (this.router.url === '/login' || this.router.url === '/' || this.router.url.startsWith('/recuperarClave') || this.router.url.startsWith('/nuevaClave') );
    }

}
