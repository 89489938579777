import { Situacion } from './../../../../shared/models/situacion.model';
import { AfterViewInit, Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from '../../../../core/services/common.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiService } from '../../../../core/services/api.service';
import { DerechoEconomico } from '../../../../shared/models/derechoEconomico.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver-es';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';


@Component({
  selector: 'app-consolidated-rights',
  templateUrl: './consolidated-rights.component.html',
  styleUrl: './consolidated-rights.component.scss'
})
export class ConsolidatedRightsComponent implements AfterViewInit{

  readonly range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  destroyRef= inject(DestroyRef)
  userName: string= ''
  userNumber: number = 0
  situacion: number = 0
  selectForm: FormGroup;
  filtroFecha: string = '';
  filtroRango: string = '';

  sortColumn: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';

  private logoBase64: string = '';

  private logoWidth: number = 0;  // Ancho original de la imagen
  private logoHeight: number = 0; // Alto original de la imagen

  constructor(
    public commonService: CommonService,
    public apiService: ApiService,
    private fb: FormBuilder,
  ) {
    this.selectForm = this.fb.group({
    });
    this.loadImageAsBase64('  ../../../../../assets/images/montepio.png').then(base64 => {
      this.logoBase64 = base64;
      const img = new Image();
      img.src = base64;
      img.onload = () => {
        this.logoWidth = img.width;
        this.logoHeight = img.height;
      };
    });
  }



  derechos: DerechoEconomico[] = [];

  loaded: Boolean = false;
  noDerechos: Boolean = false;

  displayedColumns: string[] = ['fecha', 'importe_total'];
  dataSource = new MatTableDataSource<any>();


  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit(): void {
    this.commonService.espacioComponente();
    this.getData();
    this.userName = JSON.parse(localStorage.getItem("currentUser") || '').name
    this.userName = this.userName.toLowerCase().replace(/(?:^|\s)\S/g, match => match.toUpperCase())
    this.commonService.nombre = this.userName
    this.situacion = JSON.parse(localStorage.getItem("currentUser") || '').situacion
    console.log(this.situacion)
    // Escuchar cambios en el rango de fechas
    this.range.valueChanges.subscribe(() => {
      this.applyDateFilter();  // Aplica el filtro cuando cambia el rango de fechas
    });
  }

  ngAfterViewInit() {
    const delay = 0; // <= eso es ser rapido
    setTimeout(() => {
      this.commonService.espacioComponente();
      this.dataSource.paginator = this.paginator;

    }, delay);
  }

  ngAfterViewChecked() {
    if (this.dataSource && this.paginator && !this.dataSource.paginator) {
      this.dataSource.paginator = this.paginator;
    }

    const componentPaginator = document.querySelector(".mat-mdc-paginator-range-label")
      if (componentPaginator && componentPaginator instanceof HTMLElement){
        if(window.innerWidth<767){
          componentPaginator.style.display='none'

        }

      }

    const component2 = document.getElementById('range-date');
          if (component2){
            const primerElemento = component2.children[0]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
            if (primerElemento && primerElemento instanceof HTMLElement){
              primerElemento.style.backgroundColor = 'white';
            }
            const segundoElemento = component2.children[1]; // Aquí asumimos que el segundo elemento es el índice 1 en la lista de children
            if (segundoElemento && segundoElemento instanceof HTMLElement){
              segundoElemento.style.display = 'none';
            }
          }
  }

  applyFilter(filterValue: string | null) {
    const { start, end } = this.range.value;

    this.dataSource = new MatTableDataSource<any>(this.derechos.map(({ fecha, importe_total }) => ({ fecha, importe_total })));
    this.filtroFecha=''
    if (filterValue !== null) {
      this.dataSource = new MatTableDataSource<any>(this.derechos.map(({ fecha, importe_total }) => ({ fecha, importe_total })));
      this.filtroFecha = filterValue.trim().toLowerCase();
      this.dataSource.filter = this.filtroFecha
    } else {
      this.dataSource = new MatTableDataSource<any>(this.derechos.map(({ fecha, importe_total }) => ({ fecha, importe_total })));
      this.dataSource.filter = '';
      this.filtroFecha = ''
    }

    if(start && end){
      this.dataSource = new MatTableDataSource<any>(
        this.dataSource.filteredData.filter((derecho) => {
          const fecha = new Date(derecho.fecha);
          return fecha >= start && fecha <= end;
        }).map(({ fecha, importe_total  }) => ({
          fecha,
          importe_total
         }))
      );
    }
  }

  applyDateFilter() {
    const { start, end } = this.range.value;
    this.applyFilter(this.filtroFecha)

    if(start && end){
      this.dataSource = new MatTableDataSource<any>(
        this.dataSource.filteredData.filter((derecho) => {
          const fecha = new Date(derecho.fecha);
          return fecha >= start && fecha <= end;
        }).map(({ fecha, importe_total  }) => ({
          fecha,
          importe_total
        }))
      );
    }
  }

  // Método para formatear el importe
  formatCurrency(value: number): string {
    if (value == null) {
      return '';
    }

    // Convertir el número a una cadena con dos decimales
    const parts = value.toFixed(2).split('.');

    // Formatear la parte entera con puntos como separadores de miles
    const integerPart = parts[0];
    const decimalPart = parts[1];

    // Insertar los puntos en la parte entera
    const integerFormatted = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Formar el resultado final con coma como separador decimal
    return `${integerFormatted},${decimalPart} €`;
  }

  getData() {
    this.apiService.getDerechos().pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe({
      next: (res) => {
        if (res) {
          if(this.situacion == 4){
            const currentYear = new Date().getFullYear();
            const cutoffDate = new Date(currentYear, 0, 1); // 1 de enero del año actual

            // Filtrar los resultados con fecha anterior al 1 de enero de este año
            this.derechos = res.filter((derecho: { fecha: string | number | Date; }) => new Date(derecho.fecha) < cutoffDate);
          } else{
            this.derechos = res;
          }
          if(this.derechos.length==0){
            this.noDerechos=true
          }
        this.dataSource = new MatTableDataSource<any>(this.derechos.map(({ fecha, importe_total }) => ({ fecha, importe_total })));
          this.loaded = true;
        } else {
          console.warn('Empty response received');
        }
      },
      error: (error) => {
        console.error('Error fetching derechos: ', error);
      },
    });
  }

  sortData(column: string) {
    const isAsc = this.sortColumn === column && this.sortDirection === 'asc';
    this.sortDirection = isAsc ? 'desc' : 'asc';
    this.sortColumn = column;

    this.dataSource.data = this.dataSource.filteredData.map(({ fecha, importe_total }) => ({ fecha, importe_total })).sort((a, b) => {
      const isAsc = this.sortDirection === 'asc';
      switch (column) {
        case 'fecha': return this.compare(a.fecha, b.fecha, isAsc);
        case 'importe_total': return this.compare(a.importe_total, b.importe_total, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string | undefined, b: number | string | undefined, isAsc: boolean) {
    if (a === undefined || b === undefined) return 0;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  private loadImageAsBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  exportarAExcel() {
    // Convertir los datos de la tabla a un formato adecuado para Excel
    const datosFiltrados = this.dataSource.filteredData.map(({ fecha, importe_total }) => ({
      'Fecha': fecha,
      'Importe': importe_total
    }));

    // Crear una hoja de cálculo
    const hojaDeCalculo: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datosFiltrados);

    // Ajustar el ancho de las columnas
    const columnas = [
      { header: 'Fecha', key: 'Fecha', width: 15 },
      { header: 'Importe', key: 'Importe', width: 15 }
    ];

    hojaDeCalculo['!cols'] = columnas.map(col => ({ width: col.width }));

    // Aplicar formato de moneda a la columna 'Importe'
    const formatoMoneda = { numFmt: '#,##0.00 €' }; // Formato de moneda en euros con símbolo al final
    const columnaImporte = 'B'; // La columna 'Importe' es la B en la hoja de cálculo

    // Aplica el formato de moneda a todas las celdas en la columna 'Importe'
    for (let i = 1; i <= datosFiltrados.length; i++) {
      const celda = `${columnaImporte}${i + 1}`; // i+1 para ignorar el encabezado
      hojaDeCalculo[celda] = {
        ...(hojaDeCalculo[celda] || {}),
        z: formatoMoneda.numFmt
      };
    }

    // Crear un libro de trabajo y agregar la hoja
    const libroDeTrabajo: XLSX.WorkBook = {
      Sheets: { 'Derechos económicos': hojaDeCalculo },
      SheetNames: ['Derechos económicos']
    };

    // Generar el archivo Excel en formato binario
    const excelBuffer: any = XLSX.write(libroDeTrabajo, { bookType: 'xlsx', type: 'array' });

    // Guardar el archivo Excel
    this.guardarComoExcel(excelBuffer, 'Derechos económicos');
  }

  private guardarComoExcel(buffer: any, nombreArchivo: string) {
    const blob: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `${nombreArchivo}.xlsx`);
  }

  exportarAPDF() {
    const doc = new jsPDF();

    const addHeader = () => {
      if (this.logoBase64) {
        // Utilizar el tamaño original de la imagen
        doc.addImage(this.logoBase64, 'PNG', 14, 10, this.logoWidth * 0.2, this.logoHeight * 0.2); // Ajusta el tamaño según sea necesario
      }
      doc.setFontSize(12);
      doc.setTextColor(40);

    };

    const addFooter = (data:any)=> {
      const footerText = 'Página ' + data.pageNumber;
      const pageWidth = doc.internal.pageSize.width;
      const margin = 10; // Margen desde el borde de la página

      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.text(footerText, pageWidth - margin - 20, doc.internal.pageSize.height - margin);
    }

    // Función para agregar encabezado a cada página
    const addHeaderToEachPage = (data: any) => {
      addHeader();
      addFooter(data);

    };

    // Configuración de alineación de columnas
    const columnStyles = {
      0: { // Columna 0 (Fecha)
        halign: 'left' // Alinear a la derecha
      },
      1: { // Columna 1 (Importe)
        halign: 'right' // Alinear a la derecha
      }
    };

    const addCustomHeader = () => {
      // Título del documento
      doc.setFontSize(14);
      doc.text('Derechos económicos', 14, 40);

      // Encabezado personalizado
      doc.setFontSize(10);
      doc.setTextColor(29, 82, 115); // Color del texto en el encabezado
      doc.text('Fecha', 14, 50);
      doc.text('Importe', doc.internal.pageSize.width - 40, 50, { align: 'right' });
    };

    if(this.userNumber===0){
      const user = localStorage.getItem('currentUser')
      if(user){
        this.userNumber = JSON.parse(user).number
      }
    }

    // Título del documento
    doc.text('Derechos económicos - ' + this.userName + ' ('+this.userNumber + ')', 14, 40);

    // Obtener los datos filtrados
    const datosFiltrados = this.dataSource.filteredData.map(({ fecha, importe_total }) => [
      fecha,
      this.formatCurrency(importe_total)
    ]);

    // Configuración de las columnas
    const encabezados = [
      ['Fecha', { content: 'Importe', styles: { halign: 'right' } }]
    ];
    // Agregar la tabla al PDF
    (doc as any).autoTable({
      startY: 45,
      didDrawPage: addHeaderToEachPage,
      head: encabezados,
      body: datosFiltrados,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [29, 82, 115] }, // Color de la cabecera
      theme: 'striped', // Estilo de la tabla
      margin: { top: 45 }, // Margen superior de 50
      columnStyles: columnStyles // Aplicar estilos de columna

    });

    // Guardar el PDF
    doc.save('Derechos económicos.pdf');
  }

}
