import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']  // Corrección de 'styleUrl' a 'styleUrls'
})
export class InfoComponent implements OnInit, AfterViewInit {

  order: string = "";
  userName: string = ''

  constructor(
    private route: ActivatedRoute,
    public commonService: CommonService) { }

  ngOnInit() {
    this.route.queryParams
      .pipe(
        filter((params: any) => params.submenu)
      )
      .subscribe((params: any) => {

        this.order = params.submenu;

      });

      this.commonService.espacioComponente()

      this.userName = JSON.parse(localStorage.getItem("currentUser") || '').name
      this.userName = this.userName.toLowerCase().replace(/(?:^|\s)\S/g, match => match.toUpperCase())
      this.commonService.nombre = this.userName
  }

  ngAfterViewInit() {
    const delay = 0; // <= eso es ser rapido
    setTimeout(() => {
      this.commonService.espacioComponente()
     }, delay);

  }

}
