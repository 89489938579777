
export class Login{

  id: number
  name: string
  token: string
  number: number
  situacion: number


  constructor(id: number, name: string, token: string, number: number, situacion: number) {
    this.id = id;
    this.name = name;
    this.token = token;
    this.number = number;
    this.situacion = situacion;
  }

 }
